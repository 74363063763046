import './index.css';
import { AuthContext } from "../../../../context/auth";
import userService from "../../../../services/user.service";
import { useState, useRef, useContext } from "react";
import { Form, InputGroup, Button, Modal } from "react-bootstrap";
import PasswordStrengthBar from 'react-password-strength-bar';

const ChangeConfirmationModal = (props) => {
    return (
        <Modal
            onHide={props.onHide}
            show={props.show}
            size="md"
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Senha
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Senha alterada com sucesso.
                </p>
            </Modal.Body>
        </Modal>
    );
}

const PasswordModal = (props) => {
    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [passwordNew, setPasswordNew] = useState('');
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
    const passwordRef = useRef(null);
    const passwordNewRef = useRef(null);
    const passwordRepeatRef = useRef(null);

    function clearForm() {
        setPassword('');
        setPasswordNew('');
        setPasswordRepeat('');
    }

    const changePassword = async (event) => {
        event.preventDefault();
        setErrors({});
        if (passwordNew !== passwordRepeat) {
            setErrors({ passwordRepeat: 'As senhas não correspondem.' });
            passwordRepeatRef.current.focus();
        } else {
            setIsLoading(true);
            let data = {
                password: passwordRef.current.value,
                passwordNew: passwordNewRef.current.value
            }
            let response = await userService.password(data);
            if (response.status === 'success') {
                props.onHide();
                setConfirmModal(true);
                setIsLoading(false);
            }
            if (response.status === 'error') {
                if (response.msg === 'invalid token') {
                    auth.setIsLoggedIn(false);
                }
                if (response.msg === 'User not found') {
                    auth.setIsLoggedIn(false);
                }
                if (response.msg === 'Incorrect password') {
                    setErrors({ password: 'Senha incorreta' });
                    passwordRef.current.focus();
                    setIsLoading(false);
                }
                if (response.msg === 'New password invalid') {
                    setErrors({ passwordNew: 'Senha muito curta' });
                    passwordNewRef.current.focus();
                    setIsLoading(false);
                }
            }
        }
    }

    if (confirmModal) {
        return (
            <ChangeConfirmationModal
                show={confirmModal}
                onHide={() => setConfirmModal(false)}
            />
        )
    }

    return (
        <Modal
            {...props}
            size="md"
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onShow={() => { clearForm() }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Senha
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Text className="form-change-password d-flex lh-sm mb-3 p-0" muted>Escolha uma senha forte e não a reutilize em outras contas.</Form.Text>
                <Form id="formChangePassword" className="form-change-password" onSubmit={changePassword}>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label>Senha atual:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                isInvalid={Boolean(errors.password)}
                                maxLength={20}
                                onChange={(e) => { setPassword(e.target.value) }}
                                ref={passwordRef}
                                required
                                size="md"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                            />
                            <InputGroup.Text className="form-change-password-show-pass" onClick={() => setShowPassword((prev) => !prev)}>
                                {
                                    showPassword ? (
                                        <svg viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                        </svg>
                                    ) : (
                                        <svg viewBox="0 0 16 16">
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                        </svg>
                                    )
                                }
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPasswordNew">
                        <Form.Label>Nova senha:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                isInvalid={Boolean(errors.passwordNew)}
                                maxLength={20}
                                onChange={(e) => { setPasswordNew(e.target.value) }}
                                ref={passwordNewRef}
                                required
                                size="md"
                                type={showPasswordNew ? 'text' : 'password'}
                                value={passwordNew}
                            />
                            <InputGroup.Text className="form-change-password-show-pass" onClick={() => setShowPasswordNew((prev) => !prev)}>
                                {
                                    showPasswordNew ? (
                                        <svg viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                        </svg>
                                    ) : (
                                        <svg viewBox="0 0 16 16">
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                        </svg>
                                    )
                                }
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">{errors.passwordNew}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <PasswordStrengthBar
                        minLength={6}
                        password={passwordNew}
                        scoreWords={['Muito fraca', 'Fraca', 'Boa', 'Forte', 'Muito forte']}
                        shortScoreWord='Muito curta'
                    />
                    <Form.Group className="mb-3" controlId="formGroupPasswordRepeat">
                        <Form.Label>Confirmar nova senha:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                isInvalid={Boolean(errors.passwordRepeat)}
                                maxLength={20}
                                onChange={(e) => { setPasswordRepeat(e.target.value) }}
                                ref={passwordRepeatRef}
                                required
                                size="md"
                                type={showPasswordRepeat ? 'text' : 'password'}
                                value={passwordRepeat}
                            />
                            <InputGroup.Text className="form-change-password-show-pass" onClick={() => setShowPasswordRepeat((prev) => !prev)}>
                                {
                                    showPasswordRepeat ? (
                                        <svg viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                        </svg>
                                    ) : (
                                        <svg viewBox="0 0 16 16">
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                        </svg>
                                    )
                                }
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">{errors.passwordRepeat}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isLoading} form="formChangePassword" type="submit">
                    {
                        isLoading === true ?
                            <>
                                <span className="spinner-border spinner-border-sm me-3"></span>
                                <span>Aguarde</span>
                            </>
                            :
                            <span>Alterar senha</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PasswordModal;