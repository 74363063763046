import "./index.css";
import React from "react";
import { Image } from "react-bootstrap";

function Logo(props) {
    const type = props.type ?? '.svg';
    const size = props.size + 'px' ?? 'auto';

    return (
        <div id="logo" style={{width: size}}>
            <Image src={process.env.PUBLIC_URL + '/assets/logo/default' + type} fluid />
        </div>
    );
}

export default Logo;