import React from "react";
import { Modal} from 'react-bootstrap';

const SendEmailAccountModal = (props) => {
    return (
        <Modal
            onHide={props.onHide}
            show={props.show}
            size="md"
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirmação do e-mail
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    E-mail de confirmação reenviado para {props.email}, aguarde pelo menos 5 minutos.
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default SendEmailAccountModal;