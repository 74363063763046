import React from "react";
import "./index.css";
import Logo from "../../../assets/Logo";
import { Link, NavLink } from "react-router-dom";

function SideBar({ sideBarToggle, setSideBarToggle }) {
    return (
        <div id="offcanvas" className={sideBarToggle ? "sidebar offcanvas-md offcanvas-start" : "sidebar offcanvas-md offcanvas-start show"}>
            <div className="offcanvas-header d-flex d-md-none justify-content-end">
                <button type="button" className="btn-close" onClick={() => { setSideBarToggle(true); }}></button>
            </div>
            <div className="sidebar-logo-container">
                <Link to="/" className="text-decoration-none" onClick={() => { setSideBarToggle(true); }}>
                    <Logo size={220} />
                </Link>
            </div>
            <div className="d-flex flex-column flex-nowrap side-bar container-fluid">
                <NavLink to="/simulados" className={({ isActive }) => isActive ? "sidebar-buttons-menu sidebar-button-is-active" : "sidebar-buttons-menu"} onClick={() => { setSideBarToggle(true); }}>
                    <span className="svg">
                        <svg viewBox="0 0 30 30">
                            <path d="M4.125 26.25C3.625 26.25 3.1875 26.0625 2.8125 25.6875C2.4375 25.3125 2.25 24.875 2.25 24.375V5.625C2.25 5.125 2.4375 4.6875 2.8125 4.3125C3.1875 3.9375 3.625 3.75 4.125 3.75H25.875C26.375 3.75 26.8125 3.9375 27.1875 4.3125C27.5625 4.6875 27.75 5.125 27.75 5.625V24.375C27.75 24.875 27.5625 25.3125 27.1875 25.6875C26.8125 26.0625 26.375 26.25 25.875 26.25H4.125ZM4.125 24.375H25.875V5.625H4.125V24.375ZM6.25 21.25H12.5V18.75H6.25V21.25ZM18.1875 18.75L24.375 12.5625L22.5938 10.7812L18.1875 15.2188L16.4062 13.4375L14.6562 15.2188L18.1875 18.75ZM6.25 16.25H12.5V13.75H6.25V16.25ZM6.25 11.25H12.5V8.75H6.25V11.25Z" />
                        </svg>
                    </span>
                    <span className="text">Meus simulados</span>
                </NavLink>
                <hr />
                <NavLink to="/conta" className={({ isActive }) => isActive ? "sidebar-buttons-menu sidebar-button-is-active" : "sidebar-buttons-menu"} onClick={() => { setSideBarToggle(true); }}>
                    <span className="svg">
                        <svg viewBox="0 0 20 26">
                            <path d="M10 15.5C10.6875 15.5 11.276 15.2552 11.7656 14.7656C12.2552 14.276 12.5 13.6875 12.5 13C12.5 12.3125 12.2552 11.724 11.7656 11.2344C11.276 10.7448 10.6875 10.5 10 10.5C9.3125 10.5 8.72396 10.7448 8.23438 11.2344C7.74479 11.724 7.5 12.3125 7.5 13C7.5 13.6875 7.74479 14.276 8.23438 14.7656C8.72396 15.2552 9.3125 15.5 10 15.5ZM5 20.5H15V19.7812C15 19.2812 14.8646 18.8229 14.5938 18.4062C14.3229 17.9896 13.9479 17.6771 13.4688 17.4688C12.9271 17.2396 12.3698 17.0625 11.7969 16.9375C11.224 16.8125 10.625 16.75 10 16.75C9.375 16.75 8.77604 16.8125 8.20312 16.9375C7.63021 17.0625 7.07292 17.2396 6.53125 17.4688C6.05208 17.6771 5.67708 17.9896 5.40625 18.4062C5.13542 18.8229 5 19.2812 5 19.7812V20.5ZM18.125 25.5H1.875C1.375 25.5 0.9375 25.3125 0.5625 24.9375C0.1875 24.5625 0 24.125 0 23.625V2.375C0 1.875 0.1875 1.4375 0.5625 1.0625C0.9375 0.6875 1.375 0.5 1.875 0.5H12.5312L20 7.96875V23.625C20 24.125 19.8125 24.5625 19.4375 24.9375C19.0625 25.3125 18.625 25.5 18.125 25.5ZM18.125 23.625V8.8125L11.6875 2.375H1.875V23.625H18.125Z" />
                        </svg>
                    </span>
                    <span className="text">Meus dados</span>
                </NavLink>
                <NavLink to="/pagamentos" className={({ isActive }) => isActive ? "sidebar-buttons-menu sidebar-button-is-active" : "sidebar-buttons-menu"} onClick={() => { setSideBarToggle(true); }}>
                    <span className="svg">
                        <svg viewBox="0 0 30 30">
                            <path d="M2.5 7.90625V2.5H7.90625V4.375H4.375V7.90625H2.5ZM2.5 27.5V22.0938H4.375V25.625H7.90625V27.5H2.5ZM22.0938 27.5V25.625H25.625V22.0938H27.5V27.5H22.0938ZM25.625 7.90625V4.375H22.0938V2.5H27.5V7.90625H25.625ZM22.125 22.1562H24.0938V24.125H22.125V22.1562ZM22.125 18.2188H24.0938V20.1875H22.125V18.2188ZM20.1562 20.1875H22.125V22.1562H20.1562V20.1875ZM18.1875 22.1562H20.1562V24.125H18.1875V22.1562ZM16.2188 20.1875H18.1875V22.1562H16.2188V20.1875ZM20.1562 16.25H22.125V18.2188H20.1562V16.25ZM18.1875 18.2188H20.1562V20.1875H18.1875V18.2188ZM16.2188 16.25H18.1875V18.2188H16.2188V16.25ZM24.0938 5.875V13.75H16.2188V5.875H24.0938ZM13.75 16.25V24.125H5.875V16.25H13.75ZM13.75 5.875V13.75H5.875V5.875H13.75ZM12.1875 22.5625V17.8125H7.4375V22.5625H12.1875ZM12.1875 12.1875V7.4375H7.4375V12.1875H12.1875ZM22.5312 12.1875V7.4375H17.7812V12.1875H22.5312Z" />
                        </svg>
                    </span>
                    <span className="text">Pagamentos</span>
                </NavLink>
            </div>
        </div>
    );
}

export default SideBar;