import './index.css';

const Skeleton = () => {
    return (
        <div className="container-result-skeleton">
            <h2 className='m-0'>Resultado</h2>
            <div className="relative">
                <div className='d-flex mt-5'>
                    <div className="button">
                        <span>
                            Voltar para simulados
                        </span>
                    </div>
                </div>
                <div className="board">
                    <div className="time">
                        <div className="result">
                            <div className="pizza"></div>
                            <div className="text">
                                Aprovado
                            </div>
                        </div>
                        <hr />
                        <div className="timer">
                            <span>
                                <div className="text">
                                    Tempo gasto:
                                </div>
                            </span>
                            <span>
                                <div className="text">
                                    00:00
                                </div>
                            </span>
                        </div>
                        <div className="start">
                            <span>
                                <div className="text">
                                    Iniciado:
                                </div>
                            </span>
                            <span>
                                <div className="text">
                                    00/00/0000 00:00:00
                                </div>
                            </span>
                        </div>
                        <div className="finish">
                            <span>
                                <div className="text">
                                    Finalizado:
                                </div>
                            </span>
                            <span>
                                <div className="text">
                                    00/00/0000 00:00:00
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className="amount">
                        <div className="count">
                            <div className="correct">
                                <span>
                                    <div className="text">
                                        Acertos
                                    </div>
                                </span>
                            </div>
                            <div className="pipe"></div>
                            <div className="incorrect">
                                <span>
                                    <div className="text">
                                        Erros
                                    </div>
                                </span>
                            </div>
                            <div className="pipe"></div>
                            <div className="blank">
                                <span>
                                    <div className="text">
                                        Em branco
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className="button">
                            Ver as questões erradas
                        </div>
                    </div>
                    <div className="statistic">
                        <div className="text">
                            Legislação de trânsito
                        </div>
                        <div className="text">
                            Placas de trânsito
                        </div>
                        <div className="text">
                            Direção defensiva
                        </div>
                        <div className="text">
                            Primeiros socorros
                        </div>
                        <div className="text">
                            Meio ambiente e cidadania
                        </div>
                        <div className="text">
                            Mecânia
                        </div>
                    </div>
                </div>
                <div className="shimmer-effect"></div>
            </div>
        </div>
    );
}

export default Skeleton;