import "./index.css";
import { AuthContext } from "../../../../context/auth";
import userService from "../../../../services/user.service";
import { useState, useContext } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";

function Comment(props) {
    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [comment, setComment] = useState('');
    const [publication, setPublication] = useState(true);

    const handleComment = async (event) => {
        event.preventDefault();
        setAlert(null);
        setIsLoading(true);

        let data = {
            rate: props.rate,
            comment: comment,
            publication: publication
        }
        try {
            let ratingService = await userService.rating(data);
            if (ratingService.status === 'success') {
                props.isRating();
                props.onHide();
            }
            if (ratingService.status === 'error') {
                handleError(ratingService.error);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleError = (error) => {
        if (error === 'invalid token') {
            auth.setIsLoggedIn(false);
        }
        if (error === 'User rated') {
            setAlert('Não é possível avaliar mais de uma vez.');
        }
    };

    const clearRating = () => {
        if (!isLoading) {
            setAlert(null);
            props.setRate(0);
            setComment('');
            setPublication(true);
        }
    }

    return (
        <Modal
            backdrop={isLoading ? 'static' : true}
            centered
            onExit={clearRating}
            onHide={props.onHide}
            show={props.show}
        >
            <fieldset disabled={isLoading}>
                <Modal.Header closeButton={!isLoading}>
                    <Modal.Title>Deixar um comentário</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        alert !== null && (
                            <Alert variant='danger'>{alert}</Alert>
                        )
                    }
                    <Form id='formComment' className="form-comment" onSubmit={handleComment}>
                        <div className="rating-container mb-4">
                            <div className="rating-legend">Avaliar:</div>
                            <Rating
                                initialValue={props.rate}
                                onClick={(rate) => { props.setRate(rate); }}
                                readonly={isLoading}
                                size={30}
                                tooltipDefaultText='Avaliar'
                            />
                        </div>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea">
                            <Form.Control
                                as="textarea"
                                maxLength={255}
                                onChange={(e) => { setComment(e.target.value) }}
                                placeholder="Deixe aqui seu comentário"
                                rows={5}
                                size="md"
                                value={comment}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Check
                                className="form-comment-check"
                                checked={publication}
                                label="Autorizo a publicação deste comentário"
                                onChange={() => {
                                    setPublication(() => {
                                        return !publication;
                                    })
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        form='formComment'
                        type='submit'
                        variant='primary'
                    >
                        {
                            isLoading === true ?
                                <>
                                    <span className="spinner-border spinner-border-sm me-3"></span>
                                    <span>Aguarde</span>
                                </>
                                :
                                <span>Enviar comentário</span>
                        }
                    </Button>
                </Modal.Footer>
            </fieldset>
        </Modal>
    )
}

export default Comment;