import './index.css';
import { AuthContext } from '../../../context/auth';
import { useState, useEffect, useContext, useRef } from 'react';
import { Alert, Form, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import userService from '../../../services/user.service';
import SendEmailAccountModal from './SendEmailAccountModal';
import PasswordModal from './PasswordModal';
import DeleteAccountModal from './DeleteAccountModal';
import ConfirmDeleteAccountModal from './ConfirmDeleteAccountModal';
import Skeleton from './Skeleton';

function User() {
    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUser, setIsLoadingUser] = useState(false);
    const [isLoadingEmailConfirm, setIsLoadingEmailConfirm] = useState(false);
    const [user, setUser] = useState({});
    const nameRef = useRef(null);
    const birthRef = useRef(null);
    const [alert, setAlert] = useState(null);
    const [errors, setErrors] = useState({});
    const [emailModalShow, setEmailModalShow] = useState(false);
    const [passwordModalShow, setPasswordModalShow] = useState(false);
    const [deleteAccountModalShow, setDeleteAccountModalShow] = useState(false);
    const [confirmDeleteAccountModalShow, setConfirmDeleteAccountModalShow] = useState(false);

    useEffect(() => {
        const loadUser = async () => {
            setIsLoading(true);
            let response = await userService.get();
            if (response.status === 'success') {
                setUser(response.data);
                setIsLoading(false);
            }
            if (response.status === 'error') {
                if (response.error === 'invalid token') {
                    auth.setIsLoggedIn(false);
                }
            }
        };
        loadUser();
    }, [auth]);

    const handleUserData = async (event) => {
        event.preventDefault();
        setIsLoadingUser(true);
        setErrors({});
        setAlert(null);

        let data = {
            name: nameRef.current.value,
            birth: birthRef.current.value
        }
        let response = await userService.update(data);
        if (response.status === 'success') {
            setUser(response.data);
            setAlert('Dados atualizado com sucesso.');
            setIsLoadingUser(false);
        }
        if (response.status === 'error') {
            handleError(response.error);
        }
    }

    const handleError = (error) => {
        if (error === 'invalid token') {
            auth.setIsLoggedIn(false);
        }
        if (error === 'Date of birth invalid') {
            setErrors({ birth: 'Data inválida' });
        }
        if (error === 'The name is invalid') {
            setErrors({ name: 'Insira um nome e sobrenome válido' });
        }
        if (error === 'The data is the same') {
            setAlert('Não houve alterações');
        }
        setIsLoadingUser(false);
    };

    const sendConfirmationEmail = async () => {
        setIsLoadingEmailConfirm(true);
        let response = await userService.sendEmailValidation();
        if (response.status === 'success') {
            setEmailModalShow(true);
            setIsLoadingEmailConfirm(false);
        }
        if (response.status === 'error') {
            if (response.error === 'invalid token') {
                auth.setIsLoggedIn(false);
            }
            setIsLoadingEmailConfirm(false);
        }
    }

    const confirmWithPassword = () => {
        setDeleteAccountModalShow(false);
        setConfirmDeleteAccountModalShow(true);
    }

    if (isLoading) {
        return (
            <Skeleton />
        )
    }

    return (
        <div className="container-fluid p-3 p-md-4">
            <h2>Meus dados</h2>
            <div className="container-form-user">
                <Form id="form" className="form-user-data" onSubmit={handleUserData}>
                    <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Label>Nome:</Form.Label>
                        <Form.Control
                            defaultValue={user.name}
                            isInvalid={Boolean(errors.name)}
                            placeholder="Nome completo"
                            ref={nameRef}
                            size="md"
                            type="text"
                            onChange={(e) => {
                                setUser((user) => {
                                    return {
                                        ...user,
                                        name: e.target.value
                                    }
                                }
                                )
                            }}                            
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>Data de nascimento:</Form.Label>
                        <Form.Control
                            as={InputMask}
                            defaultValue={user.birth}
                            isInvalid={Boolean(errors.birth)}
                            mask="99/99/9999"
                            maskChar="_"
                            onChange={(e) => {
                                setUser((user) => {
                                    return {
                                        ...user,
                                        birth: e.target.value
                                    }
                                }
                                )
                            }}
                            placeholder="Insira sua data de nascimento"
                            ref={birthRef}
                            size="md"
                            type="text"                            
                        />
                        <Form.Control.Feedback type="invalid">{errors.birth}</Form.Control.Feedback>
                    </Form.Group>
                    {
                        alert !== null && (
                            <Alert variant='success'>{alert}</Alert>
                        )
                    }
                    <Button className="form-user-button" disabled={isLoadingUser} type="submit">
                        {
                            isLoadingUser && (
                                <>
                                    <span className="spinner-border spinner-border-sm me-3"></span>
                                    <span>Aguarde</span>
                                </>
                            )
                        }
                        {
                            !isLoadingUser && (
                                <span>Salvar</span>
                            )
                        }
                    </Button>
                </Form>
                <hr className="mt-5" />
            </div>
            <h2 className="m-0 my-5 p-0">E-mail</h2>
            <div className="container-email-user">
                {
                    user.verifiedEmail !== 0 && (
                        <div className="container-email-user-confirmed">
                            <div className="container-email-user-confirmed-icon">
                                <svg viewBox="0 0 42 24">
                                    <path d="M11.7 23.9L0.5 12.7L2.65 10.55L11.7 19.6L13.85 21.75L11.7 23.9ZM20.2 23.9L9 12.7L11.15 10.55L20.2 19.6L39.4 0.400024L41.55 2.55002L20.2 23.9ZM20.2 15.4L18.05 13.25L30.9 0.400024L33.05 2.55002L20.2 15.4Z" />
                                </svg>
                            </div>
                            <div className="container-email-user-confirmed-text">
                                Seu e-mail foi verificado com sucesso.
                            </div>
                        </div>
                    )
                }
                {
                    user.verifiedEmail === 0 && (
                        <>
                            <div className="container-email-user-notconfirmed">
                                <div className="container-email-user-confirmed-icon">
                                    <svg viewBox="0 0 42 40">
                                        <path d="M39.8004 39.6L26.0504 25.8L20.3504 31.5L9.15039 20.3L11.3004 18.15L20.3504 27.2L23.9004 23.65L2.40039 2.19999L4.55039 0.0499878L41.9504 37.45L39.8004 39.6ZM11.8504 31.5L0.650391 20.3L2.80039 18.15L11.8504 27.2L14.0004 29.35L11.8504 31.5ZM30.3004 21.55L28.1504 19.4L39.5504 7.99999L41.7004 10.15L30.3004 21.55ZM26.0504 17.3L23.9004 15.15L31.0504 7.99999L33.2004 10.15L26.0504 17.3Z" fill="#E33F3F" />
                                    </svg>
                                </div>
                                <div className="container-email-user-confirmed-text">
                                    Seu e-mail ainda não foi confirmado.
                                </div>
                            </div>
                            <Button disabled={isLoadingEmailConfirm} variant="secondary" onClick={sendConfirmationEmail}>
                                {
                                    isLoadingEmailConfirm && (
                                        <>
                                            <span className="spinner-border spinner-border-sm me-3"></span>
                                            <span>Aguarde</span>
                                        </>
                                    )
                                }
                                {
                                    !isLoadingEmailConfirm && (
                                        <span>Reenviar confirmação de e-mail</span>
                                    )
                                }
                            </Button>
                        </>
                    )
                }
                <hr className="mt-5" />
            </div>
            <h2 className="m-0 my-5 p-0">Conta</h2>
            <div className="container-conta-user">
                <Button variant="danger" onClick={() => setPasswordModalShow(true)}>
                    Alterar senha
                </Button>
                <Button variant="dark" onClick={() => setDeleteAccountModalShow(true)}>
                    Excluir conta
                </Button>
            </div>
            <SendEmailAccountModal
                email={user.email}
                show={emailModalShow}
                onHide={() => setEmailModalShow(false)}
            />
            <PasswordModal
                show={passwordModalShow}
                onHide={() => setPasswordModalShow(false)}
            />
            <DeleteAccountModal
                confirmWithPassword={confirmWithPassword}
                show={deleteAccountModalShow}
                onHide={() => setDeleteAccountModalShow(false)}
            />
            <ConfirmDeleteAccountModal
                show={confirmDeleteAccountModalShow}
                onHide={() => setConfirmDeleteAccountModalShow(false)}
            />
        </div>
    )
}

export default User;