const simulatedsService = {
    async getAll() {
        const apiURL = process.env.REACT_APP_API + '/simulateds';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async get(data) {
        const apiURL = process.env.REACT_APP_API + '/simulated';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async new() {
        const apiURL = process.env.REACT_APP_API + '/simulateds/new';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async finish(data) {
        const apiURL = process.env.REACT_APP_API + '/finish';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async result(data) {
        const apiURL = process.env.REACT_APP_API + '/result';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async incorrect(data) {
        const apiURL = process.env.REACT_APP_API + '/incorrect';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    }
}

export default simulatedsService;