import './index.css';
import { AuthContext } from '../../../context/auth';
import simulatedsService from '../../../services/simulateds.service';
import { useState, useEffect, useContext } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import ChartProgress from '../../../components/ChartProgress';
import QuestionsIncorrectModal from './QuestionsIncorrectModal';
import Skeleton from './Skeleton';

function Result() {
    const auth = useContext(AuthContext);
    const naviget = useNavigate();
    const { idSimulated } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [simulated, setSimulated] = useState();
    const content = [
        { 0: ["LEGISLACAO DE TRANSITO", "Legislação de trânsito"] },
        { 1: ["PLACAS", "Placas de trânsito"] },
        { 2: ["DIRECAO DEFENSIVA", "Direção defensiva"] },
        { 3: ["PRIMEIROS SOCORROS", "Primeiros socorros"] },
        { 4: ["MEIO AMBIENTE E CIDADANIA", "Meio ambiente e cidadania"] },
        { 5: ["MECANICA", "Mecânica"] },
    ];
    const [questionsIncorrectModal, setQuestionsIncorrectModal] = useState(false);
    const [isLoadingQuestionsIncorrect, setIsLoadingQuestionsIncorrect] = useState(true);

    useEffect(() => {
        const loadResult = async () => {
            let response = await simulatedsService.result({
                'idSimulated': idSimulated
            });
            if (response.status === 'success') {
                setSimulated(response.data);
                setIsLoading(false);
            }
            if (response.status === 'error') {
                if (response.error === 'invalid token') {
                    auth.setIsLoggedIn(false);
                }
                naviget('/simulados');
            }
        };
        loadResult();
    }, [idSimulated, auth, naviget]);

    if (isLoading) {
        return (
            <Skeleton />
        );
    }

    return (
        <div className="containerResult">
            <h2 className='m-0'>Resultado</h2>
            <div className='d-flex mt-5'>
                <Button as={Link} className='buttonDefault' to='/'>
                    <span>
                        <svg height="20px" width="20px" viewBox="0 0 25 20">
                            <path d="M22.3929 19.5V13.5964C22.3929 12.375 21.9631 11.3345 21.1036 10.475C20.244 9.61548 19.2036 9.18571 17.9821 9.18571H3.86786L9.09286 14.4107L7.66786 15.8357L0 8.16786L7.66786 0.5L9.09286 1.925L3.86786 7.15H17.9821C19.7464 7.15 21.2619 7.77768 22.5286 9.03304C23.7952 10.2884 24.4286 11.8095 24.4286 13.5964V19.5H22.3929Z" fill="#FFFFFF" />
                        </svg>
                    </span>
                    <span>
                        Voltar para simulados
                    </span>
                </Button>
            </div>
            <div className="board">
                <div className="time">                    
                    <div className="result">
                        <ChartProgress height="140" width="140" correct={simulated.correct} incorrect={simulated.incorrect + simulated.blank} />
                        <span>
                            { simulated.result }
                        </span>
                    </div>
                    <hr />
                    <div className="timer">
                        <span>Tempo gasto:</span>
                        <span>
                            { simulated.timer }
                        </span>
                    </div>
                    <div className="start">
                        <span>Iniciado:</span>
                        <span>
                            { simulated.start }
                        </span>
                    </div>
                    <div className="finish">
                        <span>Finalizado:</span>
                        <span>
                            { simulated.finish }
                        </span>
                    </div>
                </div>
                <div className="amount">
                    <div className="count">
                        <div className="correct">                            
                            <b>
                                { simulated.correct }
                            </b>
                            <span>Acertos</span>
                        </div>
                        <div className="pipe"></div>
                        <div className="incorrect">
                            <b>
                                { simulated.incorrect }
                            </b>
                            <span>Erros</span>
                        </div>
                        <div className="pipe"></div>
                        <div className="blank">
                            <b>
                                { simulated.blank }
                            </b>
                            <span>Em branco</span>
                        </div>
                    </div>
                    <Button
                        className='buttonDefault'
                        disabled={isLoadingQuestionsIncorrect}
                        onClick={() => setQuestionsIncorrectModal(true)}
                    >
                        {
                            isLoadingQuestionsIncorrect && simulated.incorrect > 0 && (
                                <span className="spinner-border spinner-border-sm me-3"></span>
                            )
                        }
                        <span>Ver as questões erradas</span>
                    </Button>
                </div>
                <div className="statistic">
                    <div className="title">Resultado por conteúdo</div>
                    {
                        simulated.statistic.map((statistic, index) => (
                                <div key={index} className="content">
                                    {
                                        content.map((key, index) => {
                                            return (
                                                <div key={index} className="description">
                                                    { (key[index][0] === statistic[0] ? key[index][1] : '') }
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="bar">
                                        <ProgressBar
                                        striped
                                            now={statistic[1]}
                                            style={{width: '100%'}}
                                            variant={(statistic[1] >= 60 ? 'primary' : 'warning')}
                                        />
                                        <span>
                                            { `${statistic[1]}%` }
                                        </span>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
            <QuestionsIncorrectModal
                simulated={{ 'id' : idSimulated, 'incorrect' : simulated.incorrect }}
                show={questionsIncorrectModal}
                onHide={() => setQuestionsIncorrectModal(false)}
                isLoadingQuestionsIncorrect={() => setIsLoadingQuestionsIncorrect(false)}
            />
        </div>
    );
}

export default Result;