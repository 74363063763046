import './index.css';
import userService from '../../../services/user.service';
import { useState, useRef } from "react";
import { Alert, Form, Button, Modal } from "react-bootstrap";

const RedefinePasswordModal = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState({
        variant: null,
        msg: null
    });
    const [email, setEmail] = useState('');
    const emailRef = useRef(null);

    const redefinePassword = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setAlert({
            variant: null,
            msg: null
        });

        let response = await userService.sendEmailRedefinePass({
            'email': emailRef.current.value
        });
        if (response.status === 'success') {
            setAlert({
                variant: 'success',
                msg: 'Verifique no seu e-mail a redefinição da senha.'
            });
            setIsLoading(false);
        }
        if (response.status === 'error') {
            if (response.msg === 'Wait at least 24 hours') {
                setAlert({
                    variant: 'danger',
                    msg: 'Aguarde pelo menos 24 horas para solicitar nova redefinição de senha.'
                });
                setIsLoading(false);
            } else {
                setIsLoading(false);
                props.onHide(); 
            }
        }
    }

    return (
        <Modal
            {...props}
            size="md"
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onShow={() => { setEmail('') }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Redefinir senha
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    alert.msg !== null && (
                        <Alert variant={alert.variant}>{alert.msg}</Alert>
                    )
                }
                <Form id="formChangePassword" className="form-change-password" onSubmit={redefinePassword}>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Control
                            onChange={(e) => { setEmail(e.target.value )}}
                            placeholder="Insira seu e-mail"
                            ref={emailRef}
                            required
                            size="md"
                            type="email"
                            value={email}
                        />
                    </Form.Group>
                </Form>
                <div className="instructions-redefine-pass">
                    <div className='instructions-redefine-pass-title'>
                        <span>
                            Enviaremos um email com instruções de como redefinir sua senha.
                        </span>
                    </div>
                    <div className='instructions-redefine-pass-content'>
                        <div className='instructions-redefine-pass-row'>
                            <div className='instructions-redefine-pass-col'>
                                <div className="instructions-redefine-pass-number">
                                    1
                                </div>
                            </div>
                            <div className='instructions-redefine-pass-col'>
                                <div className="instructions-redefine-pass-text">
                                    Caso não encontre o e-mail, verifique na sua caixa de spam.
                                </div>
                            </div>
                        </div>
                        <div className='instructions-redefine-pass-row'>
                            <div className='instructions-redefine-pass-col'>
                                <div className="instructions-redefine-pass-number">
                                    2
                                </div>
                            </div>
                            <div className='instructions-redefine-pass-col'>
                                <div className="instructions-redefine-pass-text">
                                    O pedido de redefinição da senha somente poderá ser enviado novamente após 24 horas.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isLoading} form="formChangePassword" type="submit">
                    {
                        isLoading === true ?
                            <>
                                <span className="spinner-border spinner-border-sm me-3"></span>
                                <span>Aguarde</span>
                            </>
                            :
                            <span>Solicitar</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RedefinePasswordModal;