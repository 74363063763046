import './index.css';
import simulatedsService from "../../../../services/simulateds.service";
import { useEffect, useState } from "react";
import { Image, Modal } from "react-bootstrap";

const QuestionsIncorretModal = (props) => {
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        const loadQuestionsIncorrect = async () => {
            let response = await simulatedsService.incorrect({
                'idSimulated': props.simulated.id
            });
            if (response.status === 'success') {
                let numberQuestionsIncorrect = response.data.length;
                if (numberQuestionsIncorrect > 0) {
                    setQuestions(response.data);
                    props.isLoadingQuestionsIncorrect();
                }
            }
        }

        if (props.simulated.incorrect > 0 && questions.length === 0) {
            loadQuestionsIncorrect();
        }
    }, [questions, props]);

    function isPlateQuestion(content) {
        return (content === 'PLACAS') ? true : false;
    }

    const plate = (question) => {
        let regex = /Qual o nome técnico da placa ([ARS][\\-]\d{1}\w{0,2})\?/i;
        let plate = question.match(regex);
        return (plate) ? plate[1] : '';
    }

    return (
        <Modal
            fullscreen="lg-down"
            onHide={props.onHide}
            show={props.show}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Questões incorretas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="questions-incorrect">
                    {
                        Object.keys(questions).map((key) => {
                            return (
                                <div key={key} className='question'>
                                    <div className='title'>{questions[key].question}
                                    {
                                        isPlateQuestion(questions[key].content) && (
                                            <div className="image">
                                                <Image src={process.env.PUBLIC_URL + '/assets/plates/' + plate(questions[key].question) + '.jpg'} thumbnail />
                                            </div>
                                        )
                                    }
                                    </div>
                                    {
                                        [
                                            ['A', questions[key].alternativeA],
                                            ['B', questions[key].alternativeB],
                                            ['C', questions[key].alternativeC],
                                            ['D', questions[key].alternativeD]
                                        ].map((alternative, index) => (
                                            <div key={index} className='option'>
                                                {
                                                    alternative[0] === questions[key].correctAnswer && (
                                                        <div className='alternative-correct'>
                                                            <i>{alternative[0]}</i>
                                                            {alternative[1]}
                                                            <svg viewBox="0 0 33 23">
                                                                <path d="M11.4702 22.744L0.368164 12.0264L2.49936 9.96898L11.4702 18.6292L30.5023 0.256104L32.6335 2.31351L11.4702 22.744Z" fill="#41B26E"/>
                                                            </svg>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    alternative[0] === questions[key].answer && (
                                                        <div className='alternative-incorrect'>
                                                            <i>{alternative[0]}</i>
                                                            {alternative[1]}
                                                            <svg viewBox="0 0 29 29">
                                                                <path d="M2.70918 28.4344L0.56543 26.2907L12.3561 14.5L0.56543 2.70942L2.70918 0.565674L14.4998 12.3563L26.2904 0.565674L28.4342 2.70942L16.6436 14.5L28.4342 26.2907L26.2904 28.4344L14.4998 16.6438L2.70918 28.4344Z" />
                                                            </svg>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    alternative[0] !== questions[key].correctAnswer && alternative[0] !== questions[key].answer && (
                                                        <div className='alternative'>
                                                            <i>{alternative[0]}</i>
                                                            {alternative[1]}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        })
                    }
                    <div className="legend-container">
                        <div className="legend">
                            <i className="selected"></i>
                            <span>Resposta incorreta</span>
                        </div>
                        <div className="legend">
                            <i className="correct"></i>
                            <span>Resposta correta</span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default QuestionsIncorretModal;