import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/auth";

const root = createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter basename="/">
        <AuthProvider>
            <App />
        </AuthProvider>
    </BrowserRouter>
);