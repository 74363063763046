const apiURL = process.env.REACT_APP_API + '/token';
const error = {
    type: null,
    msg: null
};

const authToken = {
    async isValid() {
        const token = localStorage.getItem('token') ?? null;
        
        if (token !== null) {
            let headers = {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            return await fetch(apiURL, {
                method: "POST",
                headers: headers
            })
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                if (data.status === "valid") {
                    return true;
                } else {
                    return false;
                }
            })
            .catch((error) => {
                return error;
            });
        } else {
            return false;
        }
    },
    error() {
        return error;
    }
}

export default authToken;