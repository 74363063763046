import './index.css';

function Skeleton() {
    return (
        <div className='container-email-confirm-skeleton'>
            <div className="left">
                <div className='image'></div>
            </div>
            <div className="right">
                <div className='logo'></div>
                <h2>Seu e-mail foi confirmado.</h2>
                <p>
                    <span>Bem-vindo ao Simula Detra App,</span>
                    <br />
                    <span>Aproveite ao máximo seu app e use todos os recursos.</span>
                </p>
                <div className='button'>Acessar</div>
            </div>
            <div className="shimmer-effect"></div>
        </div>
    )
}

export default Skeleton;