import './index.css';
import paymentsService from '../../../../services/payments.service';
import { useState, useEffect, useRef } from 'react';
import { Image, Form, Modal, Button, Overlay, Tooltip } from "react-bootstrap";
import Skeleton from './Skeleton';
import PaidModal from './PaidModal';

const QRCode = (props) => {
    const buttonCopyQRCode = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [qrcode, setQrcode] = useState({});
    const [expire, setExpire] = useState('');
    const [timer, setTimer] = useState('');
    const [stopwatchIntervalID, setStopwatchIntervalID] = useState();
    const [checkPaymentIntervalID, setCheckPaymentIntervalID] = useState();
    const [copyQRCodeShow, setCopyQRCodeShow] = useState(false);
    const [paidIn, setPaidIn] = useState({});
    const [billingPaid, setbillingPaid] = useState(false);

    useEffect(() => {
        if (qrcode.timestamp !== undefined && qrcode.expire !== undefined) {
            setStopwatchIntervalID(() => {
                return setInterval(() => {
                    let dateCurrent = new Date();
                    let interval = (qrcode.timestamp * 1000) - dateCurrent.getTime();
            
                    let second = 1000;
                    let minute = second * 60;
            
                    let seconds = Math.floor((interval % minute) / second);
                    let minutes = Math.floor(interval / minute);
            
                    let display;
            
                    switch (minutes) {
                        case 0:
                            display = '';
                            break;
                        case 1:
                            display = minutes + ' minuto ';
                            break;
                        default:
                            display = minutes + ' minutos ';
                    }
            
                    switch (seconds) {
                        case 0:
                            display += '';
                            break;
                        case 1:
                            display += seconds + ' segundo ';
                            break;
                        default:
                            display += seconds + ' segundos ';
                    }
            
                    if (minutes <= 0 && seconds <= 0) {
                        setTimer('EXPIRADO');
                        setExpire(`Expirado em ${qrcode.expire}`);
                    } else {
                        setTimer(display);
                        setExpire(`Expira em ${qrcode.expire}`);
                    }
                }, 1000);
            });
            setCheckPaymentIntervalID(() => {
                return setInterval(async () => {
                    let data = {
                        idPay: props.pay
                    }
                    let checkPayService = await paymentsService.check(data);
                    if (checkPayService.status === 'success') {
                        if (checkPayService.data.paidIn !== undefined) {
                            setPaidIn(checkPayService.data.paidIn);
                            setbillingPaid(true);
                        }
                    }
                }, 10000);
            });
        }
    }, [props.pay, qrcode.expire, qrcode.timestamp]);

    useEffect(() => {
        if (timer === 'EXPIRADO') {
            clearInterval(stopwatchIntervalID);
        }
    }, [timer,stopwatchIntervalID]);

    useEffect(() => {
        if (billingPaid === true) {
            clearInterval(checkPaymentIntervalID);
            clearInterval(stopwatchIntervalID);
        }
    }, [billingPaid, checkPaymentIntervalID, stopwatchIntervalID]);

    useEffect(() => {
        setbillingPaid(false);
        setIsLoading(true);
        const generateQRCode = async () => {
            let data = {
                idPay: props.pay
            }
            let payService = await paymentsService.qrcode(data);
            if (payService.status === 'success') {
                if (payService.data.paidIn === undefined) {
                    setQrcode(payService.data);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 1000);
                } else {
                    setPaidIn(payService.data.paidIn);
                    setbillingPaid(true);
                }
            }
        }
        if (props.show) {
            generateQRCode();
        }
    }, [props.show, props.pay]);

    function copyQRCode() {
        setCopyQRCodeShow(true);
        navigator.clipboard.writeText(qrcode.qrcode);
        setTimeout(() => {
            setCopyQRCodeShow(false);
        }, 1000);
    }

    function onExitedModal() {
        clearInterval(stopwatchIntervalID);
        clearInterval(checkPaymentIntervalID);
        setQrcode({});
        setTimer('');
        setbillingPaid(false);
    }

    if (billingPaid) {
        return (
            <PaidModal {...props} paidIn={paidIn} />
        )
    }

    if (isLoading) {
        return (
            <Skeleton {...props} />
        )
    }

    return (
        <Modal
            animation={false}
            show={props.show}
            onHide={props.onHide}
            size="md"
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExited={() => { onExitedModal() }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Pagamento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="qrcode-container">
                    <div className='listGroup'>
                        <div className='listRow'>
                            <div className='listCol'>Valor</div>
                            <div className='listCol'>R$ {qrcode.value}</div>
                        </div>
                        <div className='listRow'>
                            <div className='listCol'>Pague em até</div>
                            <div className='listCol'>
                                <div className="stopWatch">
                                    {timer}
                                </div>
                                <div className="expire">
                                    {expire}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Image src={qrcode.imagemQrcode} rounded />
                    <Form.Control
                        defaultValue={qrcode.qrcode}
                        disabled={timer === 'EXPIRADO' ? true : false}
                        readOnly
                        size="md"
                        type="text"
                    />
                    <div className="button-container">
                        {
                            timer !== 'EXPIRADO' && (
                                <Button
                                    ref={buttonCopyQRCode}
                                    variant="outline-primary"
                                    onClick={copyQRCode}
                                >
                                    Copiar Código Pix
                                </Button>
                            )
                        }
                        <Overlay target={buttonCopyQRCode.current} show={copyQRCodeShow} placement="top">
                            {(props) => (
                                <Tooltip {...props}>
                                    Código Pix copiado
                                </Tooltip>
                            )}
                        </Overlay>
                    </div>
                </div>
                <div className="instructions">
                    <div className='instructions-title'>
                        <span>
                            Por favor, siga as instruções:
                        </span>
                    </div>
                    <div className='instructions-content'>
                        <div className='instructions-row'>
                            <div className='instructions-col'>
                                <div className="instructions-number">
                                    1
                                </div>
                            </div>
                            <div className='instructions-col'>
                                <div className="instructions-text">
                                    Copie o código Pix acima.
                                </div>
                            </div>
                        </div>
                        <div className='instructions-row'>
                            <div className='instructions-col'>
                                <div className="instructions-number">
                                    2
                                </div>
                            </div>
                            <div className='instructions-col'>
                                <div className="instructions-text">
                                    Acesse o app do seu banco ou internet banking de preferência.
                                </div>
                            </div>
                        </div>
                        <div className='instructions-row'>
                            <div className='instructions-col'>
                                <div className="instructions-number">
                                    3
                                </div>
                            </div>
                            <div className='instructions-col'>
                                <div className="instructions-text">
                                    Escolha pagar com o Pix, cole o código e finalize o pagamento.
                                </div>
                            </div>
                        </div>
                        <div className='instructions-row'>
                            <div className='instructions-col'>
                                <div className="instructions-number">
                                    4
                                </div>
                            </div>
                            <div className='instructions-col'>
                                <div className="instructions-text">
                                    Seu pagamento será aprovado em alguns segundos.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default QRCode;