import "./index.css";
import { AuthContext } from "../../context/auth";
import accountService from "../../services/account.service";
import { React, useState, useRef, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, Button, InputGroup } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength-bar";
import Logo from "../../assets/Logo";

function SignUp() {
    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const naviget = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const acceptTermsConditions = useRef(null);

    const handleCreateAcount = async (event) => {
        event.preventDefault();
        setErrors({});
        if (acceptTermsConditions.current.checked) {
            setIsLoading(true);
            let data = {
                name: name,
                email: email,
                password: password
            }
            try {
                let accountCreated = await accountService.createAccount(data);
                if (accountCreated) {
                    auth.setIsLoggedIn(true);
                    naviget('/');
                }
                if (!accountCreated) {
                    setIsLoading(false);
                    handleError();
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setErrors({ acceptTermsConditions: 'Aceitar termos e condições' });
            acceptTermsConditions.current.focus();
        }
    }

    const handleError = () => {
        if (accountService.errorCreateAccount().type === 'name') {
            setErrors({ name: 'Insira um nome e sobrenome válido.' });
            nameRef.current.focus();
        }
        if (accountService.errorCreateAccount().type === 'email') {
            if (accountService.errorCreateAccount().error === 'Email already registered') {
                setErrors({ email: 'O e-mail já está em uso.' });
            }
            if (accountService.errorCreateAccount().error === 'Email cannot be used') {
                setErrors({ email: 'O e-mail não pode mais ser usado.' });
            }
            if (accountService.errorCreateAccount().error === 'Email is invalid') {
                setErrors({ email: 'O e-mail não é válido.' });
            }
            emailRef.current.focus();
        }
        if (accountService.errorCreateAccount().type === 'password') {
            setErrors({ password: 'Senha muito curta' });
            passwordRef.current.focus();
        }
    };

    return (
        <div className="d-flex flex-column vh-100">
            <div className="signup-logo-container">
                <Logo size={320} />
            </div>
            <div className="d-flex justify-content-center">
                <h1>Criar conta</h1>
            </div>
            <div className="container-fluid p-5" style={{ maxWidth: 500 + 'px' }}>
                <Form className="form-signup" onSubmit={handleCreateAcount}>
                    <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Label>Nome:</Form.Label>
                        <Form.Control
                            isInvalid={Boolean(errors.name)}
                            onChange={(e) => { setName(e.target.value) }}
                            placeholder="Insira seu nome"
                            ref={nameRef}
                            required
                            size="md"
                            type="text"
                            value={name}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>E-mail:</Form.Label>
                        <Form.Control
                            isInvalid={Boolean(errors.email)}
                            onChange={(e) => { setEmail(e.target.value) }}
                            placeholder="Seu e-mail"
                            ref={emailRef}
                            required
                            size="md"
                            type="text"
                            value={email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label>Senha:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                isInvalid={Boolean(errors.password)}
                                maxLength={20}
                                onChange={(e) => { setPassword(e.target.value) }}
                                placeholder="Crie uma senha"
                                ref={passwordRef}
                                required
                                size="md"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                            />
                            <InputGroup.Text className="form-signup-show-pass" onClick={() => setShowPassword((prev) => !prev)}>
                                {
                                    showPassword ? (
                                        <svg viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                        </svg>
                                    ) : (
                                        <svg viewBox="0 0 16 16">
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                        </svg>
                                    )
                                }
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <PasswordStrengthBar
                        minLength={6}
                        password={password}
                        scoreWords={['Muito fraca', 'Fraca', 'Boa', 'Forte', 'Muito forte']}
                        shortScoreWord='Muito curta'
                    />
                    <Form.Check
                        className="acceptTermsConditions"
                        isInvalid={Boolean(errors.acceptTermsConditions)}
                        label={
                            <>
                                Eu aceito os 
                                <NavLink to="https://simulado.digital/terms.html" target="_blank" className="form-signup-signup">
                                    Termos e Condições de Uso
                                </NavLink>
                            </>
                        }
                        onChange={() => { setErrors({}) }}
                        ref={acceptTermsConditions}
                        size="sm"
                        type="checkbox"
                    />
                    <Button className="form-signup-button" type="submit" disabled={isLoading}>
                        {
                            isLoading === true ?
                                <>
                                    <span className="spinner-border spinner-border-sm me-3"></span>
                                    <span>Aguarde</span>
                                </>
                                :
                                <span>Finalizar</span>
                        }
                    </Button>
                </Form>
                <Form.Text className="form-signup-yesaccount">
                    Já tem um conta?
                    <NavLink to="/login" className="form-signup-signup">
                        Clique para entrar
                    </NavLink>
                </Form.Text>
            </div>
        </div>
    );
}

export default SignUp;
