import './index.css';
import userService from '../../services/user.service';
import { useState, useRef } from 'react';
import { Alert, Form, Button, Image } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import Logo from '../../assets/Logo';

function RedefinePass() {
    const { token } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState({
        variant: null,
        msg: null
    });
    const [errors, setErrors] = useState({});
    const [passChanged, setPassChanged] = useState(false);
    const [passwordNew, setPasswordNew] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const passwordNewRef = useRef(null);
    const passwordRepeatRef = useRef(null);

    const changePassword = async (event) => {
        event.preventDefault();
        setErrors({});
        setAlert({
            variant: null,
            msg: null
        });
        if (token !== undefined && token.length === 32) {
            if (passwordNew !== passwordRepeat) {
                setErrors({ passwordRepeat: 'As senhas não correspondem.' });
                passwordRepeatRef.current.focus();
            } else {
                setIsLoading(true);

                let response = await userService.redefinePass({
                    'token': token,
                    'password': passwordNewRef.current.value
                });
                if (response.status === 'success') {
                    if (response.data.msg === 'Password reset successfully') {
                        setAlert({
                            variant: 'success',
                            msg: 'Senha redefinida com sucesso.'
                        });
                        setIsLoading(false);
                        setPassChanged(true);
                    }
                }
                if (response.status === 'error') {
                    if (response.msg === 'Password reset token is not valid') {
                        setAlert({
                            variant: 'danger',
                            msg: 'Redefinição de senha expirada!'
                        });
                    }
                    if (response.msg === 'New password invalid') {
                        setErrors({ passwordNew: 'Senha muito curta' });
                    }
                    setIsLoading(false);
                }
            }
        } else {
            setAlert({
                variant: 'danger',
                msg: 'Redefinição de senha inválida!'
            });
        }
    }

    return (
        <div className='container-redefine-pass'>
            <div className="left">
                <Image className='email-marketing' src={process.env.PUBLIC_URL + '/assets/images/password-change.svg'} thumbnail />
            </div>
            <div className="right">
                <div className="redefine-pass-logo-container">
                    <Logo size={300} />
                </div>
                {
                    alert.msg !== null && (
                        <Alert variant={alert.variant}>{alert.msg}</Alert>
                    )
                }
                {
                    passChanged === true ? (
                        <Button as={Link} to='/login' className='button' variant='primary'>Clique para acessar</Button>
                    ) : (
                        <Form id="formChangePassword" className="form-change-password" onSubmit={changePassword}>
                            <Form.Group className="mb-3" controlId="formGroupPasswordNew">
                                <Form.Label>Nova senha:</Form.Label>
                                <Form.Control
                                    isInvalid={Boolean(errors.passwordNew)}
                                    maxLength={20}
                                    onChange={(e) => { setPasswordNew(e.target.value) }}
                                    ref={passwordNewRef}
                                    required
                                    size="md"
                                    type="password"
                                    value={passwordNew}
                                />
                                <Form.Control.Feedback type="invalid">{errors.passwordNew}</Form.Control.Feedback>
                            </Form.Group>
                            <PasswordStrengthBar
                                minLength={6}
                                password={passwordNew}
                                scoreWords={['Muito fraca', 'Fraca', 'Boa', 'Forte', 'Muito forte']}
                                shortScoreWord='Muito curta'
                            />
                            <Form.Group className="mb-3" controlId="formGroupPasswordRepeat">
                                <Form.Label>Confirmar nova senha:</Form.Label>
                                <Form.Control
                                    isInvalid={Boolean(errors.passwordRepeat)}
                                    maxLength={20}
                                    onChange={(e) => { setPasswordRepeat(e.target.value) }}
                                    ref={passwordRepeatRef}
                                    required
                                    size="md"
                                    type="password"
                                    value={passwordRepeat}
                                />
                                <Form.Control.Feedback type="invalid">{errors.passwordRepeat}</Form.Control.Feedback>
                            </Form.Group>
                            <Button className='button' type='submit' variant='primary'>
                                {
                                    isLoading === true ? (
                                        <>
                                            <span className="spinner-border spinner-border-sm me-3"></span>
                                            <span>Aguarde</span>
                                        </>
                                    )
                                    : (
                                        <span>Alterar senha</span>
                                    )
                                }
                            </Button>
                        </Form>
                    )
                }
            </div>
        </div>
    )
}

export default RedefinePass;