import './index.css';
import { Button } from 'react-bootstrap';

const ListPlans = (props) => {
    return (
        <div className="plan">
            <div className="card h-100 p-4">
                <div className="card-header">
                    <h3>{props.plan.description}</h3>
                </div>
                <div className="card-body">
                    <span>
                        <b>
                            { props.plan.units }
                        </b>
                        <b>
                            {
                                (props.plan.units <= 1 ? ' simulado' : ' simulados')
                            }
                        </b>
                    </span>
                    <div className="by">
                        <div className="by-text">por:</div>
                    </div>
                    <div className="card-value">
                        <div className="item">R$</div>
                        <div className="item">{props.plan.value.split(',')[0]}</div>
                        <div className="item">,{props.plan.value.split(',')[1]}</div>
                    </div>
                    <ul>
                        <li className="checked">Questões iguais da prova</li>
                        <li className="checked">Número de acertos e erros</li>
                        <li className="checked">Resultado por conteúdo</li>
                        <li className="checked">Veja as questões incorretas</li>
                    </ul>
                    <Button
                        onClick={() => { props.selectPlan(props.plan) }}
                        variant="outline-primary"
                        type="submit"
                    >
                        Escolher
                    </Button>
                </div>
            </div>
        </div>
    )
};

export default ListPlans;