import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useContext } from "react";
import { AuthContext } from "./context/auth";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import EmailConfirm from "./pages/EmailConfirm";
import RedefinePass from "./pages/RedefinePass";
import TagManager from "react-gtm-module";

const tagManagerArgs = { 
    gtmId: 'GTM-5FJNJG2R'
} 

TagManager.initialize(tagManagerArgs);

function App() {
    const auth = useContext(AuthContext);
    
    const PrivateRoutes = () => {
        return ( 
            auth.isLoggedIn ? <Outlet/> : <Navigate to="/login "/>
        )
    }
    
    return (
        <Routes>
            <Route element={<PrivateRoutes />}>
                <Route exact path="/*" element={<Dashboard />}/>
            </Route> 
            <Route path="/login" element={<Login />}/>
            <Route path="/signup" element={<SignUp />}/>
            <Route path="/email/:token" element={<EmailConfirm />}/>
            <Route path="/senha/:token" element={<RedefinePass />}/>
        </Routes>
    );
}

export default App;
