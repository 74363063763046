import "./index.css";
import React from "react";
import { Button, ProgressBar } from "react-bootstrap";
import ChartProgress from "../../../../components/ChartProgress";
import { Link } from "react-router-dom";

function ListSimulateds({ simulated }) {
    return (
        <div className="container-simulated">
            <div className="container-simulated-items">
                <div className="container-simulated-items-svg">
                    <svg viewBox="0 0 52 36">
                        <path d="M0.125 26.5886V22.5261H20.8437V26.5886H0.125ZM0.125 15.4167V11.3542H32.151V15.4167H0.125ZM0.125 4.24481V0.182312H32.151V4.24481H0.125ZM36.1458 35.3906L26.9375 26.1823L29.7812 23.2709L36.1458 29.5677L48.6719 17.0417L51.5833 19.9531L36.1458 35.3906Z" />
                    </svg>
                </div>
                <div className="container-simulated-item-info">
                    <h5>Simulado</h5>
                    <p>Início: {simulated.start}</p>
                    <p>
                        {
                            simulated.finish !== null && (
                                `Fim: ${simulated.finish}`
                            )
                        }
                    </p>
                </div>
            </div>
            {
                simulated.status === 'CONCLUIDO' && (
                    <div className="container-simulated-items">
                        <h5>Resultado</h5>
                        <div className="d-flex">
                            <span style={(simulated.result === 'APROVADO' ? { color: '#41B26E' } : { color: '#E33F3F' })}>
                                {simulated.result}
                            </span>
                        </div>
                    </div>
                )
            }
            <div className="container-simulated-items">
                {
                    simulated.status === 'CONCLUIDO' && (
                        <ChartProgress height="120" width="120" correct={simulated.correct} incorrect={ simulated.incorrect + simulated.blank } />
                    )
                }
                {
                    simulated.status === 'PENDENTE' && (
                        <ProgressBar now={60} style={{width: '150px'}}></ProgressBar>
                    )
                }
            </div>
            <div className="container-simulated-items">
                {
                    simulated.status === 'CONCLUIDO' && (
                        <Button
                            as={Link}
                            to={`/simulado/${simulated.id}/resultado`}
                            type="button"
                            variant="outline-primary"
                        >
                            Ver resultado
                        </Button>
                    )
                }
                {
                    simulated.status === 'PENDENTE' && (
                        <Button
                            as={Link}
                            to={`/simulado/${simulated.id}`}
                            type="button"
                            variant="primary"
                        >
                            Continuar
                            <span className="ms-2">
                                <svg width="20px" height="20px" viewBox="0 0 16 16">
                                    <path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" fill="#FFFFFF" />
                                </svg>
                            </span>
                        </Button>
                    )
                }
            </div>
        </div>
    );
}

export default ListSimulateds;