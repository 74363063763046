import "./index.css";
import { AuthContext } from "../../../context/auth";
import simulatedsService from "../../../services/simulateds.service";
import questionsService from "../../../services/questions.service";
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import * as Icons from "../../../assets/Icons";
import Skeleton from "./Skeleton";

function Simulated() {
    useEffect(() => {
        window.dataLayer.push({ 
            'event': 'conversion',
            'email': localStorage.getItem('userEmail') ?? null
        });
    }, []);

    const auth = useContext(AuthContext);
    const { idSimulated } = useParams();
    const naviget = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [number, setNumber] = useState();
    const [questions, setQuestions] = useState();
    const [previousDisabled, setPreviousDisable] = useState(false);
    const [nextDisabled, setNextDisable] = useState(false);
    const [isLastQuestion, setIsLastQuestion] = useState(false);
    const [answer, setAnswer] = useState();
    const content = [
        { 0: ["LEGISLACAO DE TRANSITO", "Legislação de trânsito"] },
        { 1: ["PLACAS", "Placas de trânsito"] },
        { 2: ["DIRECAO DEFENSIVA", "Direção defensiva"] },
        { 3: ["PRIMEIROS SOCORROS", "Primeiros socorros"] },
        { 4: ["MEIO AMBIENTE E CIDADANIA", "Meio ambiente e cidadania"] },
        { 5: ["MECANICA", "Mecânica"] },
    ];

    useEffect(() => {
        const loadSimulated = async () => {
            let response = await simulatedsService.get({
                'idSimulated': idSimulated
            });
            if (response.status === 'success') {
                let simulated = response.data;
                if (simulated.finish !== null) {
                    naviget(`/simulado/${idSimulated}/resultado`);
                }
                loadQuestions();
            }
            if (response.status === 'error') {
                if (response.error === 'invalid token') {
                    auth.setIsLoggedIn(false);
                }
                naviget('/simulados');
            }
        };

        const loadQuestions = async () => {
            let response = await questionsService.get({
                'idSimulated': idSimulated
            });
            if (response.status === 'success') {
                let questions = response.data;
                if (questions !== undefined && Object.keys(questions).length > 0) {
                    setQuestions(questions);
                    let lastQuestionAnswered = questions.findIndex(question => question.answer === null);
                    let goOn = (lastQuestionAnswered < 0) ? 29 : lastQuestionAnswered;
                    setNumber(goOn);
                    setIsLoading(false);
                }
            }
            if (response.status === 'error') {  
                if (response.error === 'invalid token') {
                    auth.setIsLoggedIn(false);
                }
                naviget('/simulados');
            }
        };

        loadSimulated();
    }, [idSimulated, auth, naviget]);

    function isPlateQuestion(content) {
        return (content === 'PLACAS') ? true : false;
    }

    const plate = (question) => {
        let regex = /Qual o nome técnico da placa ([ARS][\\-]\d{1}\w{0,2})\?/i;
        let plate = question.match(regex);
        return (plate) ? plate[1] : '';
    }

    useEffect(() => {
        setPreviousDisable(number < 1 ? true : false);
        setNextDisable(number > 28 ? true : false);
        setIsLastQuestion(number === 29 ? true : false);
        window.scrollTo(0, 0);
    }, [number, questions]);

    const next = () => {
        let next = number + 1;
        setNumber(next);
    };

    const previous = () => {
        let previous = number - 1;
        setNumber(previous);
    };

    const setIcon = (content) => {
        switch (content) {
            case 'DIRECAO DEFENSIVA':
                return <Icons.IconDrivingHands />;
            case 'LEGISLACAO DE TRANSITO':
                return <Icons.IconStackOfBooks />;
            case 'MECANICA':
                return <Icons.IconCarService />;
            case 'MEIO AMBIENTE E CIDADANIA':
                return <Icons.IconNaturePreservation />;
            case 'PLACAS':
                return <Icons.IconSignBoard />;
            case 'PRIMEIROS SOCORROS':
                return <Icons.IconFirstAidBox />;
            default:
                return false;
        }
    }

    useEffect(() => {
        const updateAnswer = async () => {
            let response = await questionsService.answer({
                'idAnswerSheet': questions[number].idAnswerSheet,
                'idSimulated': idSimulated,
                'idQuestion': questions[number].idQuestion,
                'answer': questions[number].answer
            });
            if (response.status === 'error') {
                if (response.error === 'invalid token') {
                    auth.setIsLoggedIn(false);
                }
            }
        };

        if (answer !== undefined) {
            questions[number].answer = answer;
            setAnswer();
            updateAnswer();
        }
    }, [answer, auth, idSimulated, number, questions]);

    const finish = async () => {
        setIsLoading(true);
        let response = await simulatedsService.finish({
            'idSimulated': idSimulated
        });
        if (response.status === 'success') {
            naviget(`/simulado/${idSimulated}/resultado`);
        }
        if (response.status === 'error') {
            if (response.error === 'invalid token') {
                auth.setIsLoggedIn(false);
            }
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <Skeleton />
        );
    }

    return (
        <div className="simulated-container">
            <div className="question-container">
                <h2 className="m-0">Simulado</h2>
                <div className="fade-effect">
                    <div className="question">
                        <div className="number">PERGUNTA {number + 1}</div>
                        <div className="title">{questions[number].question}</div>
                        {
                            isPlateQuestion(questions[number].content) && (
                                <div className="image">
                                    <Image src={process.env.PUBLIC_URL + '/assets/plates/' + plate(questions[number].question) + '.jpg'} thumbnail />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="alternatives-container">
                <div className="board">
                    <div className="content">
                        {setIcon(questions[number].content)}
                        <span>
                            {
                                content.map((key, index) => {
                                    return (
                                        <div key={index} className="description">
                                            { (key[index][0] === questions[number].content ? key[index][1] : '') }
                                        </div>
                                    )
                                })
                            }
                            <b>
                                {`Questão ${number + 1} de 30`}
                            </b>
                        </span>
                    </div>
                    <div className="options">
                        {
                            [
                                ['A', questions[number].alternativeA],
                                ['B', questions[number].alternativeB],
                                ['C', questions[number].alternativeC],
                                ['D', questions[number].alternativeD]
                            ].map((alternative, index) => (
                                <div key={index}>
                                    <input
                                        checked={questions[number].answer === alternative[0]}
                                        className="btn-check"
                                        id={`alternative${alternative[0]}`}
                                        name="alternative"
                                        onChange={(e) => setAnswer(e.currentTarget.value)}
                                        type="radio"
                                        value={alternative[0]}
                                    />
                                    <label htmlFor={`alternative${alternative[0]}`}>
                                        <span>
                                            {alternative[1]}
                                        </span>
                                        <i>
                                            {alternative[0]}
                                        </i>
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                    <div className="navegation">
                        <Button bsPrefix="button-navigation" onClick={previous} disabled={previousDisabled}>
                            <svg viewBox="0 0 16 16">
                                <path d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                            </svg>
                        </Button>
                        {
                            isLastQuestion && (
                                <Button bsPrefix="button-finish" onClick={finish}>
                                    Finalizar
                                </Button>
                            )
                        }
                        <Button bsPrefix="button-navigation" onClick={next} disabled={nextDisabled}>
                            <svg viewBox="0 0 16 16">
                                <path d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Simulated;