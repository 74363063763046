import { useState, useRef} from "react";
import { Form, Button, Modal, ListGroup } from 'react-bootstrap';

const DeleteAccountModal = (props) => {
    const [errors, setErrors] = useState({});
    const acceptConditions = useRef(null);
    
    const isAcceptConditions = () => {
        if (acceptConditions.current.checked) {
            props.confirmWithPassword();
        } else {
            setErrors({ acceptConditions: 'Aceitar termos e condições' });
        }
    };

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Excluir sua conta
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h6 className="mt-2 mb-4">Leia com atenção. Seu e-mail não poderá ser usado novamente e todo esse conteúdo será excluído.</h6>
                <ListGroup className='mt-3' style={{ fontSize: '.8rem' }}>
                    <ListGroup.Item>Dados pessoais como nome, data de nascimento e e-mail.</ListGroup.Item>
                    <ListGroup.Item>Simulados realizados, resultados e estatísticas.</ListGroup.Item>
                    <ListGroup.Item>Histórico de pagamentos realizados.</ListGroup.Item>
                </ListGroup>
                <Form className="form-signup">
                    <Form.Text className="form-signup-info-password d-flex lh-sm mt-4 mb-4 p-0" muted>
                        Esta operação ocasionará a exclusão definitiva e irreversível de todos os seus dados.
                    </Form.Text>
                    <Form.Check
                        className="acceptConditions"
                        isInvalid={Boolean(errors.acceptConditions)}
                        label="Sim, quero excluir permanentemente esta conta, bem como todos os dados contidos nela."
                        onChange={() => { setErrors({}) }}
                        ref={acceptConditions}
                        size="sm"
                        type="checkbox"
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={isAcceptConditions} variant='dark'>Excluir conta</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteAccountModal;