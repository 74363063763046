import "./index.css";
import { AuthContext } from "../../context/auth";
import authToken from "../../services/auth.token";
import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "../../components/Loading";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import Main from "../content/Main";
import User from "../content/User";
import Payments from "../content/Payments";
import Simulated from "../content/Simulated";
import Result from "../content/Result";

function Dashboard() {
    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [sideBarToggle, setSideBarToggle] = useState(true);

    function toggleSideBar() {
        setSideBarToggle(!sideBarToggle);
    }

    useEffect(() => {
        const checkToken = async () => {
            let tokenIsValid = await authToken.isValid();
            if (tokenIsValid) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
                auth.setIsLoggedIn(false);
            }
        }
        checkToken();
    }, [auth]);

    if (isLoading) {
        return (
            <Loading />
        );
    }

    return (
        <div className={sideBarToggle ? "dashboard-container dashboard-sidebar-toggle" : "dashboard-container"}>
            <TopBar toggleSideBar={toggleSideBar} />
            <SideBar sideBarToggle={sideBarToggle} setSideBarToggle={setSideBarToggle} />
            <Routes>
                <Route path="/simulados" element={<Main />} />
                <Route path="/conta" element={<User />} />
                <Route path="/pagamentos" element={<Payments />} />
                <Route path="/simulado/:idSimulated" element={<Simulated />} />
                <Route path="/simulado/:idSimulated/resultado" element={<Result />} />
                <Route path="*" element={<Main />} />
            </Routes>
        </div>
    );
}

export default Dashboard;