import "./index.css";
import React from "react";

const Skeleton = () => {
    return (
        <div className="container-simulated-skeleton">
            <div className="question-container">
                <div className="title">
                    <span>Simulado</span>
                </div>
                <div className="question">
                    <div className="number">PERGUNTA 1</div>
                    <div className="title">
                        A observação constante das condições do condutor, da via e do veículo que tem relação direta com segurança do trânsito, denomina-se:
                    </div>
                </div>
            </div>
            <div className="alternatives-container">
                <div className="board">
                    <div className="content">
                        <span>
                            DIRECAO DEFENSIVA
                        </span>
                    </div>
                    <div className="options">
                        <div className="alternative">
                            <span>
                                Automatismo.
                            </span>
                            <i>A</i>
                        </div>
                        <div className="alternative">
                            <span>
                                Direção defensiva preventiva.
                            </span>
                            <i>B</i>
                        </div>
                        <div className="alternative">
                            <span>
                                Direção agressiva.
                            </span>
                            <i>C</i>
                        </div>
                        <div className="alternative">
                            <span>
                                Direção de alto risco.
                            </span>
                            <i>D</i>
                        </div>
                    </div>
                    <div className="navegation">
                        <div className="button"></div>
                        <div className="button"></div>
                    </div>
                </div>
            </div>
            <div className="shimmer-effect"></div>
        </div>
    );
}

export default Skeleton;