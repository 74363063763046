const errorPay = {
    type: null,
    msg: null
};

const paymentsService = {
    async get() {
        const apiURL = process.env.REACT_APP_API + '/payments';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async plans() {
        const apiURL = process.env.REACT_APP_API + '/plans';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async pay(data) {
        const apiURL = process.env.REACT_APP_API + '/pay';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    errorPay() {
        return errorPay;
    },
    async qrcode(data) {
        const apiURL = process.env.REACT_APP_API + '/qrcode';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async check(data) {
        const apiURL = process.env.REACT_APP_API + '/checkpay';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    }
}

export default paymentsService;