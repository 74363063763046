import './index.css';
import { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Skeleton from './Skeleton';
import Logo from '../../assets/Logo';
import userService from '../../services/user.service';

function EmailConfirm() {
    const { token } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        const confirm = async () => {
            setIsLoading(true);
            let response = await userService.emailValidation({
                'token': token
            });
            if (response.status === 'success') {
                if (response.data.msg === 'Verified email') {
                    setIsValid(true);
                    setIsLoading(false);
                }
            }
            if (response.status === 'error') {
                setIsValid(false);
                setIsLoading(false);
            }
        }

        if (token !== undefined && token.length === 32) {
            confirm();
        } else {
            setIsValid(false);
            setIsLoading(false);
        }
    }, [token]);

    if (isLoading) {
        return (
            <Skeleton />
        )
    }

    return (
        <div className='container-email-confirm'>
            <div className="left">
                <Image className='email-marketing' src={process.env.PUBLIC_URL + '/assets/images/email-confirm.svg'} thumbnail />
            </div>
            <div className="right">
                <Logo mode="default" size="20" />
                {
                    isValid && (
                        <h2>Seu e-mail foi confirmado.</h2>
                    )
                }
                {
                    !isValid && (
                        <h2 className='text-danger'>E-mail de validação inexistente ou inválido.</h2>
                    )
                }
                <p>
                    Bem-vindo ao Simulado Digital,
                    <br />
                    Aproveite ao máximo seu app e use todos os recursos.
                </p>
                <Button as={Link} to='/login' className='button' variant='primary'>Acessar</Button>
            </div>
        </div>
    )
}

export default EmailConfirm;