import "./index.css";
import React from "react";
import { AuthContext } from "../../../context/auth";
import simulatedsService from "../../../services/simulateds.service";
import userService from "../../../services/user.service";
import { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ListSimulateds from "./ListSimulateds";
import ListSimulatedsSkeleton from "./ListSimulatedsSkeleton";
import Comment from "./Comment";
import { Rating } from "react-simple-star-rating";

function Main() {
    useEffect(() => {
        window.dataLayer.push({ 
            'event': 'conversion',
            'email': localStorage.getItem('userEmail') ?? null
        });
    }, []);

    const auth = useContext(AuthContext);
    const naviget = useNavigate();
    const [isLoadingSimulateds, setIsLoadingSimulateds] = useState(true);
    const [isLoadingNewSimulated, setIsLoadingNewSimulated] = useState(false);
    const [simulateds, setSimulateds] = useState();
    const [rate, setRate] = useState(0);
    const [isRating, setIsRating] = useState(false);
    const [CommentShow, setCommentShow] = useState(false);

    const newSimulated = async () => {
        setIsLoadingNewSimulated(true);
        let response = await simulatedsService.new();
        if (response.status === 'success') {
            naviget(`/simulado/${response.idSimulated}`);
        }
        if (response.status === 'error') {
            if (response.error === 'All units used' || response.error === 'No payments found') {
                naviget('/pagamentos');
            }
            if (response.error === 'invalid token') {
                auth.setIsLoggedIn(false);
            }
            setIsLoadingNewSimulated(false);
        }
    };

    useEffect(() => {
        const loadSimulateds = async () => {
            let response = await simulatedsService.getAll();
            if (response.status === 'success') {
                setSimulateds(response.data);
                setIsLoadingSimulateds(false);
            }
            if (response.status === 'error') {
                if (response.error === 'invalid token') {
                    auth.setIsLoggedIn(false);
                }
            }
        };
        loadSimulateds();
    }, [auth]);

    useEffect(() => {
        const checkRating = async () => {
            try {
                let checkRatingService = await userService.checkRating();
                if (checkRatingService.status === 'success') {
                    if (checkRatingService.rate > 0) {
                        setIsRating(true);
                        setRate(checkRatingService.rate);
                    } else {
                        setIsRating(false);
                        setRate(0);
                    }
                    setIsRating(checkRatingService.rate);
                }
                if (checkRatingService.status === 'error') {
                    setIsRating(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
        checkRating();
    }, []);

    const handleRating = async (rate) => {
        setRate(rate);
        setCommentShow(true);
    }

    return (
        <div className="container-fluid p-3 p-md-4">
            <h2 className="m-0">Meus simulados</h2>
            {
                isLoadingSimulateds && (
                    <>
                        <ListSimulatedsSkeleton />
                        <ListSimulatedsSkeleton />
                        <ListSimulatedsSkeleton />
                    </>
                )
            }
            {
                !isLoadingSimulateds && Array.isArray(simulateds) && !simulateds.filter(simulated => simulated.status === 'PENDENTE').length && (
                    <Button
                        className="mt-5 mb-5 d-flex gap-1 align-items-center"
                        disabled={isLoadingNewSimulated}
                        onClick={newSimulated}
                        type="button"
                        variant="primary"
                    >
                        {
                            isLoadingNewSimulated === true ?
                                <>
                                    <span className="spinner-border spinner-border-sm me-3"></span>
                                    <span>Aguarde</span>
                                </>
                                :
                                <>
                                    <span>
                                        <svg height="30px" width="30px" viewBox="0 0 16 16">
                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" fill="#FFFFFF" />
                                        </svg>
                                    </span>
                                    <span>Iniciar simulado</span>
                                </>
                        }
                    </Button>
                )
            }
            {
                !isLoadingSimulateds && Array.isArray(simulateds) && !simulateds.length && (
                    <div className="alert alert-warning d-inline-flex">
                        Você ainda não realizou nenhum simulado.
                    </div>
                )
            }
            {
                !isLoadingSimulateds && Array.isArray(simulateds) && simulateds.length && (
                    <div className="rating-container mt-4 mb-4">
                        <div className="rating-legend">
                            {
                                isRating ? "Avaliado:" : "Avaliar:"
                            }
                        </div>
                        <Rating
                            initialValue={rate}
                            onClick={handleRating}
                            readonly={isRating}
                            size={30}
                            tooltipDefaultText='Avaliar'
                        />
                        <Comment
                            isRating={() => setIsRating(true)}
                            onHide={() => setCommentShow(false)}
                            rate={rate}
                            setRate={(rate) => setRate(rate)}
                            show={CommentShow}                            
                        />
                    </div>
                )
            }
            {
                !isLoadingSimulateds && Array.isArray(simulateds) && simulateds.map(
                    (simulated) => (
                        <ListSimulateds key={simulated.id} simulated={simulated} />
                    )
                )
            }
        </div>
    );
}

export default Main;