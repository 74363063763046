import './index.css';
import { useState, useRef, useContext } from "react";
import { Form, InputGroup, Button, Modal } from "react-bootstrap";
import { AuthContext } from "../../../../context/auth";
import accountService from "../../../../services/account.service";

const ConfirmDeleteAccountModal = (props) => {
    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const passwordRef = useRef(null);

    const deleteAccount = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setErrors({});

        let data = {
            password: passwordRef.current.value
        }
        let response = await accountService.delete(data);
        if (response.status === 'success') {
            auth.setIsLoggedIn(false);
        }
        if (response.status === 'error') {
            if (response.msg === 'invalid token') {
                auth.setIsLoggedIn(false);
            }
            if (response.msg === 'User not found') {
                auth.setIsLoggedIn(false);
            }
            if (response.msg === 'Incorrect password') {
                setErrors({ password: 'Senha incorreta' });
                passwordRef.current.focus();
                setIsLoading(false);
            }
        }
    };

    return (
        <Modal
            {...props}
            size="md"
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirmar exclusão
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Para concluir, confirme sua identidade
                </p>
                <Form id="formDeleteAccount" className="form-delete-account" onSubmit={deleteAccount}>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <InputGroup>
                            <Form.Control
                                isInvalid={Boolean(errors.password)}
                                onChange={(e) => { setPassword(e.target.value) }}
                                ref={passwordRef}
                                placeholder="Insira sua senha"
                                required
                                size="md"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                            />
                            <InputGroup.Text className="form-delete-account-show-pass" onClick={() => setShowPassword((prev) => !prev)}>
                                {
                                    showPassword ? (
                                        <svg viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                        </svg>
                                    ) : (
                                        <svg viewBox="0 0 16 16">
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                        </svg>
                                    )
                                }
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button form="formDeleteAccount" type="submit" variant='dark'>
                    {
                        isLoading === true ?
                            <>
                                <span className="spinner-border spinner-border-sm me-3"></span>
                                <span>Aguarde</span>
                            </>
                            :
                            <span>Excluir</span>
                    }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmDeleteAccountModal;