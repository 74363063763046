const apiURL = process.env.REACT_APP_API + '/login';
const errorAuthenticate = {
    type: null,
    msg: null
};

const authService = {
    async authenticate(data) {
        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json' 
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            if (data.status === "success") {
                localStorage.setItem('userName', data.userName);
                localStorage.setItem('userEmail', data.userEmail);
                localStorage.setItem('token', data.token);

                return true;
            }
            if (data.status === "error") {
                const errorMsg = {
                    email: 'E-mail não cadastrado',
                    password: 'A senha está incorreta'
                }
                errorAuthenticate.type = data.type;
                errorAuthenticate.msg = errorMsg[data.type];
                
                return false;
            }
        })
        .catch((error) => {
            return error;
        });
    },
    errorAuthenticate() {
        return errorAuthenticate;
    },
    logout() {
        localStorage.removeItem('userName');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('token');
    }
}

export default authService;