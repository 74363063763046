import './index.css';
import { AuthContext } from "../../../context/auth";
import paymentsService from "../../../services/payments.service";
import { useState, useEffect, useContext } from "react";
import ListPlans from "./ListPlans";
import ListPayments from "./ListPayments";
import PaymentModal from "./PaymentModal";
import QRCode from "./QRCode";
import ListPaymentsSkeleton from "./ListPaymentsSkeleton";

function Payments() {
    const auth = useContext(AuthContext);
    const [isLoadingPlans, setIsLoadingPlans] = useState();
    const [isLoadingPay, setIsLoadingPay] = useState();
    const [payments, setPayments] = useState();
    const [paymentModalShow, setPaymentModalShow] = useState(false);
    const [QRCodeModalShow, setQRCodeModalShow] = useState(false);
    const [pay, setPay] = useState({});
    const [plans, setPlans] = useState({});
    const [plan, setPlan] = useState({});

    useEffect(() => {
        const loadPlans = async () => {
            setIsLoadingPlans(true);
            let response = await paymentsService.plans();
            if (response.status === 'success') {
                setPlans(response.data);
                setIsLoadingPlans(false);
            }
            if (response.status === 'error') {
                if (response.error === 'invalid token') {
                    auth.setIsLoggedIn(false);
                }
            }
        };

        loadPlans();
    }, [auth]);

    useEffect(() => {
        const loadPayments = async () => {
            setIsLoadingPay(true);
            let response = await paymentsService.get();
            if (response.status === 'success') {
                setPayments(response.data);
                setIsLoadingPay(false);
            }
            if (response.status === 'error') {
                if (response.error === 'invalid token') {
                    auth.setIsLoggedIn(false);
                }
            }
        };

        loadPayments();
    }, [auth, QRCodeModalShow]);

    const selectPlan = (plan) => {
        setPlan(plan);
        setPaymentModalShow(true);
    };

    const showQRCode = (idPay) => {
        setPay(idPay);
        setPaymentModalShow(false);
        setQRCodeModalShow(true);
    }

    return (
        <div className="container-fluid p-3 p-md-4">
            <h2>Escolha um plano</h2>
            <div className="container-plans">
                {
                    !isLoadingPlans && Array.isArray(plans) && plans.map(
                        (plan) => (
                            <ListPlans
                                key={plan.id}
                                plan={plan}
                                selectPlan={selectPlan}
                            />
                        )
                    )
                }
            </div>
            <h2 className="mt-5 mb-4">Histórico de pagamentos</h2>
            {
                Array.isArray(payments) && !payments.length && (
                    <div className="alert alert-light d-inline-flex">
                        Você ainda não realizou nenhum pagamento.
                    </div>
                )
            }
            {
                !isLoadingPay && Array.isArray(payments) && payments.map(
                    (payment) => (
                        <ListPayments
                            key={payment.id}
                            payment={payment}
                            showQRCode={showQRCode}
                        />
                    )
                )
            }
            {
                isLoadingPay && (
                    <>
                        <ListPaymentsSkeleton />
                        <ListPaymentsSkeleton />
                        <ListPaymentsSkeleton />
                    </>
                )
            }
            <PaymentModal
                show={paymentModalShow}
                onHide={() => setPaymentModalShow(false)}
                plan={plan}
                showQRCode={showQRCode}
            />
            <QRCode
                show={QRCodeModalShow}
                onHide={() => setQRCodeModalShow(false)}
                pay={pay}
            />
        </div>
    );
}

export default Payments;