import React from "react";
import Chart from "react-apexcharts";

function ChartProgress({ height, width, correct, incorrect }) {
    const state = {
        options: {
            chart: {
                stacked: true,
                stackType: "100%",
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            colors: ['#1566FE', '#FF891D'],
            plotOptions: {
                pie: {
                    customScale: 1,
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true
                            },
                            value: {
                                show: true,
                                fontSize: '18px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 600,
                                color: undefined,
                                offsetY: -9,
                                formatter: function (val) {
                                    return val + '%'
                                }
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: '',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 600,
                                color: '#373d3f',
                                formatter: function (w) {
                                    let total = w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b
                                    }, 0);
                                    return  Math.floor((w.globals.seriesTotals[0] / (total)) * 100) + '%';
                                }
                            }
                        },
                        size: '60%'
                    },
                    expandOnClick: false
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                enabled: true
            },
            labels: ['Acertos', 'Erros']
        },
        series: [correct, incorrect]
    };

    return (
        <Chart
            options={state.options}
            series={state.series}
            type="donut"
            height={height}
            width={width}
        />
    );
}

export default ChartProgress;