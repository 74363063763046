import './index.css';
import React from "react";

const Skeleton = () => {
    return (
        <div className="container-user-skeleton">
            <h2 className='m-0'>Meus dados</h2>
            <div className="container-user">
                <div className="label">
                    <div className="text">Nome:</div>
                </div>
                <div className="input">Insira seu nome completo</div>
                <div className="label">
                    <div className="text">Data de nascimento:</div>
                </div>
                <div className="input">Insira sua data de nascimento</div>
                <div className="button">Salvar</div>
                <hr className="mt-5" />
                <div className="shimmer-effect"></div>
            </div>
            <h2 className="m-0 mt-5 p-0">E-mail</h2>
            <div className="container-email">
                <div className="label">
                    <div className="text">Seu e-mail ainda não foi confirmado.</div>
                </div>
                <div className="button">Reenviar confirmação de e-mail</div>
                <hr className="mt-5" />
                <div className="shimmer-effect"></div>
            </div>
            <h2 className="m-0 mt-5 p-0">Conta</h2>
            <div className="container-conta">
                <div className="button">Alterar senha</div>
                <div className="button">Excluir conta</div>
                <div className="shimmer-effect"></div>
            </div>
        </div>
    )
}

export default Skeleton;