const userService = {
    async get() {
        const apiURL = process.env.REACT_APP_API + '/user';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async update(data) {
        const apiURL = process.env.REACT_APP_API + '/userupdate';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async password(data) {
        const apiURL = process.env.REACT_APP_API + '/changepassword';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async emailValidation(data) {
        const apiURL = process.env.REACT_APP_API + '/emailValidation';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async sendEmailValidation() {
        const apiURL = process.env.REACT_APP_API + '/sendEmailValidation';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async redefinePass(data) {
        const apiURL = process.env.REACT_APP_API + '/redefinePass';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async sendEmailRedefinePass(data) {
        const apiURL = process.env.REACT_APP_API + '/sendEmailRedefinePass';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async rating(data) {
        const apiURL = process.env.REACT_APP_API + '/rating';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    },
    async checkRating() {
        const apiURL = process.env.REACT_APP_API + '/checkrating';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    }
}

export default userService;