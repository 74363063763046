import "./index.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const PaidModal = (props) => {
    // Auto Escola Aê tag
    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = "AE-TAG";
        script.src = 'https://autoescolaae.com.br/tagPurchase.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Modal
            show={props.show}
            size="md"
            fullscreen="sm-down"
            onHide={props.onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className="paid-modal-content">
                    <div className="icon">
                        <svg viewBox="0 0 54 54">
                            <path d="M26.9997 53.6666C23.2219 53.6666 19.7108 52.9888 16.4663 51.6332C13.2219 50.2777 10.3997 48.3999 7.99967 45.9999C5.59967 43.5999 3.7219 40.7777 2.36634 37.5332C1.01079 34.2888 0.333008 30.7777 0.333008 26.9999C0.333008 23.2666 1.01079 19.7777 2.36634 16.5333C3.7219 13.2888 5.59967 10.4666 7.99967 8.06658C10.3997 5.66658 13.2219 3.7777 16.4663 2.39992C19.7108 1.02214 23.2219 0.333252 26.9997 0.333252C30.333 0.333252 33.4441 0.866585 36.333 1.93325C39.2219 2.99992 41.8219 4.46659 44.133 6.33325L41.2663 9.19992C39.3108 7.64436 37.133 6.44436 34.733 5.59992C32.333 4.75547 29.7552 4.33325 26.9997 4.33325C20.5552 4.33325 15.1663 6.49992 10.833 10.8333C6.49967 15.1666 4.33301 20.5555 4.33301 26.9999C4.33301 33.4444 6.49967 38.8332 10.833 43.1666C15.1663 47.4999 20.5552 49.6666 26.9997 49.6666C33.4441 49.6666 38.833 47.4999 43.1663 43.1666C47.4997 38.8332 49.6663 33.4444 49.6663 26.9999C49.6663 25.6666 49.5663 24.3666 49.3663 23.0999C49.1663 21.8333 48.8663 20.5999 48.4663 19.3999L51.533 16.3333C52.2441 17.9777 52.7775 19.6888 53.133 21.4666C53.4886 23.2444 53.6663 25.0888 53.6663 26.9999C53.6663 30.7777 52.9774 34.2888 51.5997 37.5332C50.2219 40.7777 48.333 43.5999 45.933 45.9999C43.533 48.3999 40.7108 50.2777 37.4663 51.6332C34.2219 52.9888 30.733 53.6666 26.9997 53.6666ZM23.0663 39.1333L12.0663 28.0666L15.0663 25.0666L23.0663 33.0666L50.6663 5.46659L53.733 8.46659L23.0663 39.1333Z" />
                        </svg>
                    </div>
                    <div className="title">
                        Pagamento confirmado!
                    </div>
                    <div className="text">
                        A confirmação foi realizada em
                        <br />
                        {props.paidIn}
                    </div>
                    <Button
                        as={Link}
                        variant="primary"
                        to="/simulados"
                        type="submit"
                    >
                        Ir para simulados
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default PaidModal;