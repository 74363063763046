import "./index.css";
import React from "react";

const ListSimulatedsSkeleton = () => {
    return (
        <div className="container-list-simulated-skeleton">
            <div className="block">
                <div className="icon">
                    <svg viewBox="0 0 52 36">
                        <path d="M0.125 26.5886V22.5261H20.8437V26.5886H0.125ZM0.125 15.4167V11.3542H32.151V15.4167H0.125ZM0.125 4.24481V0.182312H32.151V4.24481H0.125ZM36.1458 35.3906L26.9375 26.1823L29.7812 23.2709L36.1458 29.5677L48.6719 17.0417L51.5833 19.9531L36.1458 35.3906Z">
                        </path>
                    </svg>
                </div>
            </div>
            <div className="block">
                <div className="text">Simulado</div>
                <div className="text">Início: 0000-00-00 00:00:00</div>
                <div className="text">Fim: 0000-00-00 00:00:00</div>
            </div>
            <div className="block">
                <div className="text">Resultado</div>
                <div className="text">Aprovado ou Reprovado</div>
            </div>
            <div className="block">
                <div className="pizza"></div>
            </div>
            <div className="block">
                <div className="button">Ver resultado</div>
            </div>
            <div className="shimmer-effect"></div>
        </div>
    );
}

export default ListSimulatedsSkeleton;