import "./index.css";
import { Modal } from "react-bootstrap";

const Skeleton = (props) => {
    return (
        <Modal
            contentClassName="skeleton-qrcode-modal-content"
            show={props.show}
            size="md"
            fullscreen="sm-down"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span>
                        Pagamento
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="skeleton-qrcode-modal-container">
                    <div className='listGroup'>
                        <div className='listRow'>
                            <div className='listCol'>
                                <span>
                                    Valor
                                </span>
                            </div>
                            <div className='listCol'>
                                <span>
                                    R$ 0,00
                                </span>                                
                            </div>
                        </div>
                        <div className='listRow'>
                            <div className='listCol'>
                                <span>
                                    Pague em até
                                </span>
                            </div>
                            <div className='listCol'>
                                <span>
                                    29 minutos 59 seconds
                                </span>
                                <span style={{fontSize: '.6rem'}}>
                                    Expira em __/__/____ __:___
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="img"></div>
                    <div className="input">
                        Código Pix Copia e Cola
                    </div>
                    <div className="button-container">
                        <div className="button">
                            Copiar Código Pix
                        </div>
                    </div>
                </div>
                <div className="skeleton-qrcode-modal-instructions">
                    <div className='skeleton-qrcode-modal-instructions-title'>
                        <span>
                            Por favor, siga as instruções:
                        </span>
                    </div>
                    <div className='skeleton-qrcode-modal-instructions-content'>
                        <div className='skeleton-qrcode-modal-instructions-row'>
                            <div className='skeleton-qrcode-modal-instructions-col'>
                                <div className="skeleton-qrcode-modal-instructions-number">
                                    1
                                </div>
                            </div>
                            <div className='skeleton-qrcode-modal-instructions-col'>
                                <div className='skeleton-qrcode-modal-instructions-text'>
                                    Copie o código Pix acima.
                                </div>
                            </div>
                        </div>
                        <div className='skeleton-qrcode-modal-instructions-row'>
                            <div className='skeleton-qrcode-modal-instructions-col'>
                                <div className="skeleton-qrcode-modal-instructions-number">
                                    2
                                </div>
                            </div>
                            <div className='skeleton-qrcode-modal-instructions-col'>
                                <div className='skeleton-qrcode-modal-instructions-text'>
                                    Acesse o app do seu banco ou internet banking de preferência.
                                </div>
                            </div>
                        </div>
                        <div className='skeleton-qrcode-modal-instructions-row'>
                            <div className='skeleton-qrcode-modal-instructions-col'>
                                <div className="skeleton-qrcode-modal-instructions-number">
                                    3
                                </div>
                            </div>
                            <div className='skeleton-qrcode-modal-instructions-col'>
                                <div className='skeleton-qrcode-modal-instructions-text'>
                                    Escolha pagar com o Pix, cole o código e finalize o pagamento.
                                </div>
                            </div>
                        </div>
                        <div className='skeleton-qrcode-modal-instructions-row'>
                            <div className='skeleton-qrcode-modal-instructions-col'>
                                <div className="skeleton-qrcode-modal-instructions-number">
                                    4
                                </div>
                            </div>
                            <div className='skeleton-qrcode-modal-instructions-col'>
                                <div className='skeleton-qrcode-modal-instructions-text'>
                                    Seu pagamento será aprovado em alguns segundos.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <div className="shimmer-effect"></div>
        </Modal>
    );
}

export default Skeleton;