import "./index.css";
import { AuthContext } from "../../context/auth";
import authService from "../../services/auth.service";
import { React, useState, useRef, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Form, InputGroup, Button } from "react-bootstrap";
import Logo from "../../assets/Logo";
import RedefinePasswordModal from "./RedefinePasswordModal";

function Login() {
    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [redefinePasswordModalShow, setRedefinePasswordModalShow] = useState(false);
    const [errors, setErrors] = useState({});
    const naviget = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPass, setShowPass] = useState(false);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const handleLogin = async (event) => {
        event.preventDefault();
        setErrors({});
        setIsLoading(true);

        try {
            let data = {
                email: email,
                password: password
            }

            if (await authService.authenticate(data)) {
                auth.setIsLoggedIn(true);
                naviget('/');
            } else {
                setIsLoading(false);
                handleError();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleError = () => {
        if (authService.errorAuthenticate().type === 'email') {
            setErrors({ email: 'E-mail não cadastrado' });
            emailRef.current.focus();
        }
        if (authService.errorAuthenticate().type === 'account') {
            setErrors({ email: 'E-mail não cadastrado' });
            emailRef.current.focus();
        }
        if (authService.errorAuthenticate().type === 'password') {
            setErrors({ password: 'A senha está incorreta' });
            passwordRef.current.focus();
        }
    };

    useEffect(() => {
        const handleLogout = () => {
            authService.logout();
            auth.setIsLoggedIn(false);
        }
        
        handleLogout();
    }, [auth]);

    return (
        <div className="d-flex flex-column vh-100">
            <div className="login-logo-container">
                <Logo size={320} />
            </div>
            <div className="d-flex justify-content-center">
                <h1>Acessar conta</h1>
            </div>
            <div className="container-fluid p-5" style={{ maxWidth: 500 + 'px' }}>
                <Form className="form-login" onSubmit={handleLogin}>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>E-mail:</Form.Label>
                        <Form.Control
                            isInvalid={Boolean(errors.email)}
                            onChange={(e) => { setEmail(e.target.value )}}
                            placeholder="Seu e-mail"
                            ref={emailRef}
                            required
                            size="md"
                            type="email"
                            value={email}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label>Senha:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                isInvalid={Boolean(errors.password)}
                                onChange={(e) => { setPassword(e.target.value )}}
                                placeholder="Insira sua senha"
                                ref={passwordRef}
                                required
                                size="md"
                                type={showPass ? 'text' : 'password'}
                                value={password}
                            />
                            <InputGroup.Text className="form-login-show-pass" onClick={() => setShowPass((prev) => !prev)}>
                                {
                                    showPass ? (
                                        <svg viewBox="0 0 16 16">
                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                        </svg>
                                    ) : (
                                        <svg viewBox="0 0 16 16">
                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                                        </svg>
                                    )
                                }
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <NavLink onClick={() => setRedefinePasswordModalShow(true)} className="form-login-forgot-password">
                        Não lembra sua senha?
                    </NavLink>
                    <Button className="form-login-button" type="submit" disabled={isLoading}>
                        {
                            isLoading === true ?
                                <>
                                    <span className="spinner-border spinner-border-sm me-3"></span>
                                    <span>Aguarde</span>
                                </>
                            :
                                <span>Entrar</span>
                        }
                    </Button>
                </Form>
                <Form.Text className="form-login-noaccount">
                    Não tem um conta?
                    <NavLink to="/signup" className="form-login-signup">
                        Clique para cadastrar
                    </NavLink>
                </Form.Text>
            </div>
            <RedefinePasswordModal
                show={redefinePasswordModalShow}
                onHide={() => setRedefinePasswordModalShow(false)}
            />
        </div>
    );
}

export default Login;
