import React from "react";

const IconSignBoard = () => {
    return (
        <svg viewBox="0 0 510 510">
            <g>
                <path d="m412.004 438.326c-29.107-41.171-84.379-52.581-127.004-27.923l-15 49.799 15 49.798h225c0-17.31 0-53.712 0-63.934-30.129-19.29-67.106-21.222-97.996-7.74z" fill="#ECE6F2" />
                <path d="m225 510c-15.192 0-209.806 0-225 0 0-17.31 0-53.712 0-63.934 30.074-19.254 67.039-21.251 97.996-7.74 29.177-41.269 84.459-52.534 127.004-27.923l15 49.799z" fill="#FFF5F5" />
                <path d="m225 0v75l15 15 15-15 15-37.5-15-37.5c-10.492 0-19.508 0-30 0z" fill="#607DA5" />
                <path d="m285 0c-10.492 0-19.508 0-30 0v75l15 15 15-15c0-20.194 0-63.236 0-75z" fill="#385C8E" />
                <path d="m225 315v195h30l15-97.5-15-97.5-15-15z" fill="#607DA5" />
                <path d="m285 315-15-15-15 15v195h30c0-10.822 0-184.178 0-195z" fill="#385C8E" />
                <path d="m225 75v120l15 15 15-15 15-60-15-60c-10.492 0-19.508 0-30 0z" fill="#FD9" />
                <path d="m510 135c-12.117-16.155-37.942-50.589-45-60-11.664 0-198.336 0-210 0v120l15 15 30-15h165c12.117-16.155 37.942-50.589 45-60z" fill="#FB3" />
                <path d="m45 195c-12.117 16.155-37.942 50.589-45 60 12.117 16.156 37.942 50.589 45 60h210l15-60-15-60c-11.664 0-198.336 0-210 0z" fill="#FF884D" />
                <path d="m300 195c-14.981 0-30.019 0-45 0v120h45c0-14.518 0-105.386 0-120z" fill="#FF4D4D" />
            </g>
        </svg>
    )
}

const IconDrivingHands = () => {
    return (
        <svg viewBox="0 0 512 512">
            <g>
                <path d="m212.751 252.292 28.43 134.202h29.638l28.43-134.202z" fill="#DDEBFD" />
                <path d="m397.182 222.222-10.15-35.174-9.167 8.816c-7.338 7.057-18.02 9.428-27.633 6.074-14.862-5.186-31.379-9.182-49.055-11.724-14.383-2.061-29.535-3.166-45.178-3.166s-30.795 1.105-45.178 3.166c-17.676 2.541-34.193 6.537-49.055 11.724-9.613 3.355-20.294.984-27.633-6.074l-9.167-8.816-10.15 35.174v30.709l105.606 14.496h71.152l105.606-14.496v-30.709z" fill="#766D78" />
                <path d="m316.75 225.706-3.58 24.844c-.769 5.337-2.617 10.286-5.292 14.641-6.81 11.075-19.035 18.305-32.746 18.305h-38.264c-13.711 0-25.936-7.23-32.746-18.305-2.675-4.355-4.523-9.304-5.292-14.641l-3.58-24.844c-1.563-10.886 1.797-21.21 8.282-28.889 3.575-4.234 8.695-6.852 14.197-7.512 12.299-1.475 25.112-2.257 38.271-2.257 13.16 0 25.972.782 38.271 2.257 5.502.66 10.621 3.279 14.197 7.512 6.485 7.679 9.845 18.003 8.282 28.889z" fill="#A29AA5" />
                <path d="m310.812 244.48c-7.195 11.701-20.111 19.34-34.598 19.34h-40.428c-14.486 0-27.403-7.639-34.598-19.34-2.316-3.77-4.032-7.966-5.028-12.459l2.67 18.528c.769 5.337 2.617 10.286 5.292 14.641 6.81 11.075 19.035 18.305 32.746 18.305h38.264c13.711 0 25.936-7.23 32.746-18.305 2.675-4.355 4.523-9.304 5.292-14.641l2.67-18.528c-.996 4.493-2.713 8.69-5.028 12.459z" fill="#8C818F" />
                <circle cx="256" cy="233.065" fill="#BED9FD" r="16.053" />
                <path d="m256 54.497c-93.823 0-169.881 76.058-169.881 169.881s76.058 169.88 169.881 169.88 169.881-76.058 169.881-169.88-76.058-169.881-169.881-169.881zm0 303.35c-73.713 0-133.469-59.756-133.469-133.47s59.756-133.469 133.469-133.469 133.469 59.756 133.469 133.47-59.756 133.469-133.469 133.469z" fill="#8C818F" />
                <path d="m256 370.576c-89.841 0-163.382-69.743-169.463-158.039-.27 3.913-.418 7.86-.418 11.841 0 93.823 76.058 169.881 169.881 169.881s169.881-76.059 169.881-169.881c0-3.982-.148-7.928-.418-11.841-6.081 88.296-79.622 158.039-169.463 158.039z" fill="#766D78" />
                <path d="m67.977 255.03-26.794-54.149c-11.1-22.432-2.493-49.627 19.492-61.588l4.253-2.314 19.628-18.145c9.262-8.562 24.219-5.3 29.074 6.342l18.425 43.071c.425.993 1.81 1.056 2.322.104l10.538-19.567c1.413-2.627 4.684-3.617 7.317-2.215l6.725 3.582c11.161 5.945 16.072 19.329 11.404 31.082l-62.848 158.26-57.963-19.905z" fill="#FFCDBE" />
                <path d="m60.675 139.294 4.253-2.314 1.293-1.195c-8.31 13.28-9.71 30.404-2.288 45.405l25.607 51.751c.763 1.543.923 3.315.446 4.97l-25.749 86.722-14.688-5.044 18.427-64.557-26.794-54.149c-11.099-22.434-2.492-49.628 19.493-61.589z" fill="#EBAE9D" />
                <path d="m47.325 290.86-47.037 135.494c-.938 2.702.492 5.653 3.194 6.591l69.911 24.27c2.702.938 5.653-.492 6.591-3.194l47.037-135.494c.983-2.833-.516-5.927-3.349-6.91l-69.438-24.105c-2.832-.984-5.926.515-6.909 3.348z" fill="#DF646E" />
                <path d="m47.325 290.86c.983-2.833 4.077-4.332 6.91-3.349l22.495 7.809c-2.638-.916-5.574.638-6.558 3.471l-48.736 140.387-17.954-6.233c-2.702-.938-4.132-3.889-3.194-6.591z" fill="#DC4955" />
                <path d="m444.023 255.03 26.794-54.149c11.1-22.432 2.493-49.627-19.492-61.588l-4.253-2.314-19.628-18.145c-9.262-8.562-24.219-5.3-29.074 6.342l-18.425 43.071c-.425.993-1.81 1.056-2.322.104l-10.538-19.567c-1.413-2.627-4.684-3.617-7.317-2.215l-6.725 3.582c-11.161 5.945-16.072 19.329-11.404 31.082l62.848 158.26 57.964-19.907z" fill="#FFCDBE" />
                <path d="m451.325 139.294-4.253-2.314-1.293-1.195c8.31 13.28 9.71 30.404 2.288 45.405l-25.607 51.751c-.763 1.543-.923 3.315-.446 4.97l25.749 86.722 14.688-5.044-18.427-64.557 26.794-54.149c11.099-22.434 2.492-49.628-19.493-61.589z" fill="#EBAE9D" />
                <path d="m464.675 290.86 47.037 135.494c.938 2.702-.492 5.653-3.194 6.591l-69.911 24.27c-2.702.938-5.653-.492-6.591-3.194l-47.037-135.494c-.983-2.833.516-5.927 3.349-6.91l69.438-24.105c2.832-.984 5.926.515 6.909 3.348z" fill="#DF646E" />
                <path d="m464.675 290.86c-.983-2.833-4.077-4.332-6.91-3.349l-22.495 7.809c2.638-.916 5.574.638 6.558 3.471l48.735 140.387 17.954-6.233c2.702-.938 4.132-3.889 3.194-6.591z" fill="#DC4955" />
            </g>
        </svg>
    )
}

const IconStackOfBooks = () => {
    return (
        <svg viewBox="0 0 512 512">
            <path d="m257.763 377.018 180.646-127.309-249.486-45.403-183.485 120.21z" fill="#F4D177" />
            <path d="m254.246 250.629-180.646-114.388 249.502-40.806 182.34 106.581z" fill="#F66" />
            <path d="m190.004 151.886-147.904-93.645 204.275-33.42 149.921 86.025z" fill="#02A9F4" />
            <path d="m271.295 384.953c-2.5-3.953-5.919-6.711-9.693-7.42l-253.341-45.404s10.855 31.389 10.468 52.275c-.291 16.92-10.323 48.936-10.323 48.936l253.325 45.404c3.774.709 7.193-.824 9.678-3.855 2.483-3.033 4.032-7.549 4.016-12.807l-.08-62.871c-.001-5.258-1.55-10.34-4.05-14.258zm232.453-174.663-253.325 40.791c-3.79.646-7.209 3.113-9.709 6.677-2.5 3.549-4.048 8.081-4.048 12.807l-.064 56.516c-.017 4.742 1.516 8.791 4 11.484 2.483 2.742 5.903 4.113 9.677 3.484l253.341-40.807s-7.274-18.032-9.339-46.629c-1.355-18.726 9.467-44.323 9.467-44.323zm-316.986 16.452 207.389-33.436s-9.113-15.323-9.984-36.613c-.693-16.854 10.097-37.855 10.097-37.855l-207.405 33.419c-3.081.517-5.888 2.549-7.936 5.452-2.049 2.887-3.307 6.597-3.307 10.483l-.064 46.259c-.017 3.854 1.242 7.193 3.274 9.419 2.033 2.227 4.839 3.339 7.936 2.872z" fill="#FFF3E4" />
            <path d="m326.301 328.716-59.377 41.846c-1.254-.515-2.546-.908-3.871-1.157l-253.341-45.405c-4.403-.807-8.774 2.186-9.581 6.67-.806 4.492 2.178 8.781 6.678 9.588l253.26 45.389c1.048.201 2.693 1.273 4.258 3.75 1.758 2.742 2.758 6.314 2.758 9.822l.081 62.887c.016 3.072-.79 5.896-2.146 7.549-.968 1.154-1.516 1-1.839.959l-253.324-45.403c-4.387-.814-8.774 2.186-9.581 6.67-.807 4.492 2.178 8.781 6.678 9.588l253.244 45.387c1.146.219 2.274.324 3.387.324 4.446 0 8.65-1.68 12.095-4.826l172.278-123.078c4.936-3.5 8.226-10.613 8.209-19.371l-.039-31.013z" fill="#FBAC0F" />
            <path d="m502.313 293.09-253.389 40.814c-.597.121-1.322.104-2.258-.936-1.129-1.242-1.822-3.428-1.807-5.879l.064-56.525c0-2.822.92-5.758 2.549-8.064 1.29-1.83 2.871-3.032 4.258-3.266l253.325-40.791c4.517-.726 7.564-4.959 6.839-9.468-.726-4.5-5.049-7.621-9.451-6.839 0 0-202.579 32.949-258.119 42.209l-33.841-21.428-31.983 5.083-12.613-33.321-92.287-58.438c-7.952 2.338-17.323 13.016-17.678 25.048l-.081 56.952c0 7.855 3.274 14.242 8.209 17.387l173.682 111.373c-.067-.043-.116-.086-.18-.129 3.21 2.346 6.979 3.588 10.903 3.588 1.032 0 2.081-.082 3.129-.258l253.341-40.809c4.517-.725 7.564-4.959 6.839-9.467-.709-4.506-5.08-7.563-9.451-6.836z" fill="#E63431" />
            <path d="m392.845 185.153-207.921 33.153c-.549-.597-1.113-1.911-1.113-3.847l.064-46.267c0-2.032.646-4.104 1.791-5.726.968-1.371 1.984-1.968 2.5-2.057l207.405-33.419c4.517-.726 7.564-4.968 6.839-9.468-.727-4.508-5.064-7.581-9.452-6.839 0 0-210.947 34.306-212.576 35.109l-138.282-87.551c-6.5 1.903-14.177 10.646-14.468 20.484l-.484 48.718c0 6.468 2.678 11.693 6.726 14.274 0 0 140.626 90.616 146.535 92.625 3.25 1.105 6.757.69 7.66.553l207.389-33.436c4.517-.726 7.564-4.968 6.839-9.468-.727-4.508-5.05-7.58-9.452-6.838z" fill="#006AB3" />
        </svg>
    )
}

const IconCarService = () => {
    return (
        <svg viewBox="0 0 512 512">
            <g>
                <g>
                    <path d="m129.995 41.338h381.505v428.771h-381.505z" fill="#DFEBFA" />
                </g>
                <g>
                    <path d="m129.995 41.338h25.829v428.771h-25.829z" fill="#B1DBFC" />
                </g>
                <g>
                    <path d="m129.995 41.338h381.505v110.182h-381.505z" fill="#FF4756" />
                </g>
                <g>
                    <path d="m129.995 41.338h25.829v110.182h-25.829z" fill="#D90d17" />
                </g>
                <g>
                    <path d="m374.413 113.299h-147.035c-10.297 0-18.732-8.435-18.732-18.761 0-10.326 8.435-18.761 18.732-18.761h147.035c6.05-9.104 16.434-15.125 28.214-15.125 13.206 0 24.637 7.534 30.251 18.558h-16.987c-8.435 0-15.329 6.894-15.329 15.329 0 8.435 6.893 15.329 15.329 15.329h16.987c-5.614 11.024-17.045 18.557-30.251 18.557-11.78 0-22.164-5.992-28.214-15.126z" fill="#576473" />
                </g>
                <g>
                    <path d="m171.851 192.561h297.821v277.548h-297.821z" fill="#576473" />
                </g>
                <g>
                    <path d="m171.851 192.561h88.628v277.548h-88.628z" fill="#3B4855" />
                </g>
                <g>
                    <path d="m171.851 192.561h297.821v64.66h-297.821z" fill="#FF4756" />
                </g>
                <g>
                    <path d="m171.852 206.929h297.821v14.37h-297.821z" fill="#B00B13" />
                </g>
                <g>
                    <path d="m171.852 228.483h297.821v14.37h-297.821z" fill="#B00B13" />
                </g>
                <g>
                    <path d="m9.517 470.109c-3.985 0-7.184-3.229-7.184-7.185s3.199-7.184 7.184-7.184h270.305c3.985 0 7.185 3.229 7.185 7.184 0 3.956-3.2 7.185-7.185 7.185z" fill="#3B4855" />
                </g>
                <g>
                    <path d="m11.757 426.479h339.62c6.195 0 11.257 5.09 11.257 11.286 0 6.196-5.061 11.257-11.257 11.257h-339.62c-6.196 0-11.257-5.061-11.257-11.257 0-6.196 5.061-11.286 11.257-11.286z" fill="#DFEBFA" />
                </g>
                <g>
                    <path d="m266.384 353.965c-17.423-31.908-37.697-66.231-56.342-66.231h-99.477c-62.13 0-40.722 57.185-90.315 75.19-6.283 2.472-10.733 8.61-10.733 15.707v47.848h344.099v-34.701c0-38.424-63.817-34.73-87.232-37.813z" fill="#FF4756" />
                </g>
                <g>
                    <path d="m210.624 383.838h-14.456c-3.968 0-7.184-3.217-7.184-7.185s3.217-7.185 7.184-7.185h14.456c3.968 0 7.184 3.217 7.184 7.185.001 3.968-3.216 7.185-7.184 7.185z" fill="#DFEBFA" />
                </g>
                <g>
                    <path d="m133.631 383.838h-14.456c-3.968 0-7.184-3.217-7.184-7.185s3.217-7.185 7.184-7.185h14.456c3.968 0 7.184 3.217 7.184 7.185s-3.216 7.185-7.184 7.185z" fill="#DFEBFA" />
                </g>
                <g>
                    <path d="m141.688 287.734h-31.123c-62.13 0-40.722 57.185-90.315 75.19-6.283 2.472-10.733 8.61-10.733 15.707v47.848h39.442v-42.001c0-7.679 4.828-14.311 11.606-16.987 48.633-17.656 34.119-70.158 81.123-79.757z" fill="#D90D17" />
                </g>
                <g>
                    <path d="m154.137 308.269h-43.572c-25.742 0-28.563 13.671-36.591 31.036-2.211 4.77-4.741 9.337-7.621 13.642h87.784z" fill="#23A8FE" />
                </g>
                <g>
                    <path d="m209.345 308.269h-31.472v44.677h64.486c-5.789-10.238-23.852-41.07-33.014-44.677z" fill="#23A8FE" />
                </g>
                <g>
                    <path d="m177.872 308.269h13.322v44.677h-13.322z" fill="#0193FA" />
                </g>
                <g>
                    <path d="m83.543 352.947c13.118-13.409 17.976-30.948 27.371-44.677h-.349c-25.742 0-28.563 13.671-36.591 31.036-2.211 4.77-4.741 9.337-7.621 13.642h17.19z" fill="#0193FA" />
                </g>
                <g>
                    <path d="m283.662 470.662c19.372 0 35.166-15.794 35.166-35.137 0-19.372-15.794-35.166-35.166-35.166s-35.166 15.794-35.166 35.166c0 19.343 15.794 35.137 35.166 35.137z" fill="#665E68" />
                </g>
                <g>
                    <path d="m283.662 449.37c7.65 0 13.874-6.224 13.874-13.845 0-7.65-6.225-13.874-13.874-13.874-7.621 0-13.874 6.225-13.874 13.874-.001 7.621 6.253 13.845 13.874 13.845z" fill="#DFEBFA" />
                </g>
                <g>
                    <path d="m267.053 426.479c.756-2.821 1.862-5.556 3.258-8.057 4.654-8.319 12.565-14.573 22.048-16.987-2.792-.698-5.701-1.076-8.697-1.076-9.104 0-17.365 3.432-23.619 9.104-2.821 2.56-5.236 5.585-7.126 8.959-1.396 2.501-2.501 5.236-3.258 8.057-.756 2.88-1.163 5.905-1.163 9.046 0 1.833.145 3.636.407 5.381.436 2.822 1.222 5.556 2.298 8.115 5.294 12.711 17.83 21.641 32.461 21.641 2.996 0 5.905-.378 8.697-1.076-10.791-2.734-19.576-10.471-23.793-20.565-1.047-2.56-1.833-5.294-2.269-8.115-.262-1.745-.407-3.549-.407-5.381 0-3.142.407-6.167 1.163-9.046z" fill="#554E56" />
                </g>
                <g>
                    <path d="m283.662 421.65c1.774 0 3.461.32 5.032.931-5.177 2.007-8.872 7.039-8.872 12.944 0 5.876 3.694 10.908 8.872 12.915-1.571.611-3.258.931-5.032.931-7.65 0-13.874-6.195-13.874-13.845-.001-7.68 6.224-13.876 13.874-13.876z" fill="#B1DBFC" />
                </g>
                <g>
                    <path d="m11.757 426.479h37.202v22.542h-37.202c-6.196 0-11.257-5.061-11.257-11.257 0-6.195 5.061-11.285 11.257-11.285z" fill="#B1DBFC" />
                </g>
                <g>
                    <path d="m72.577 470.662c19.372 0 35.166-15.794 35.166-35.137 0-19.372-15.794-35.166-35.166-35.166s-35.166 15.794-35.166 35.166c0 19.343 15.795 35.137 35.166 35.137z" fill="#665E68" />
                </g>
                <g>
                    <path d="m72.577 449.37c7.65 0 13.874-6.224 13.874-13.845 0-7.65-6.225-13.874-13.874-13.874-7.621 0-13.874 6.225-13.874 13.874 0 7.621 6.254 13.845 13.874 13.845z" fill="#DFEBFA" />
                </g>
                <g>
                    <path d="m55.969 426.479c.756-2.821 1.862-5.556 3.258-8.057 4.654-8.319 12.566-14.573 22.048-16.987-2.792-.698-5.701-1.076-8.697-1.076-9.104 0-17.365 3.432-23.619 9.104-2.821 2.56-5.236 5.585-7.126 8.959-1.396 2.501-2.501 5.236-3.258 8.057-.756 2.88-1.163 5.905-1.163 9.046 0 1.833.145 3.636.407 5.381.436 2.822 1.222 5.556 2.298 8.115 5.294 12.711 17.83 21.641 32.461 21.641 2.996 0 5.905-.378 8.697-1.076-10.791-2.734-19.576-10.471-23.793-20.565-1.047-2.56-1.833-5.294-2.269-8.115-.262-1.745-.407-3.549-.407-5.381-.001-3.142.407-6.167 1.163-9.046z" fill="#554E56" />
                </g>
                <g>
                    <path d="m72.577 421.65c1.774 0 3.461.32 5.003.931-5.177 2.007-8.843 7.039-8.843 12.944 0 5.876 3.665 10.908 8.843 12.915-1.542.611-3.229.931-5.003.931-7.65 0-13.874-6.195-13.874-13.845 0-7.68 6.225-13.876 13.874-13.876z" fill="#B1DBFC" />
                </g>
                <g>
                    <path d="m9.517 378.224v.407 20.594h14.02c5.788 0 10.5-4.741 10.5-10.5 0-5.788-4.712-10.5-10.5-10.5h-14.02z" fill="#FFD301" />
                </g>
                <g>
                    <path d="m353.616 399.224v-7.446-.029c-.029-17.51-13.293-26.265-29.96-30.919v23.386c0 8.261 6.748 15.009 14.98 15.009h14.98z" fill="#FFD301" />
                </g>
                <g>
                    <path d="m218.071 110.798c-5.643-3.258-9.424-9.337-9.424-16.26 0-10.326 8.435-18.761 18.732-18.761h147.035c6.05-9.104 16.434-15.125 28.214-15.125 11.14 0 21.001 5.352 27.196 13.642-5.672-4.218-12.682-6.719-20.273-6.719-11.78 0-22.135 6.021-28.214 15.125h-147.007c-10.326 0-18.761 8.464-18.761 18.761 0 3.403.902 6.574 2.502 9.337z" fill="#3B4855" />
                </g>
            </g>
        </svg>
    )
}

const IconPlanetEarth = () => {
    return (
        <svg viewBox="0 0 512 512.00002">
            <path d="m386.101562 294.554688c0-93.058594-75.238281-168.496094-168.050781-168.496094s-168.050781 75.4375-168.050781 168.496094c0 93.058593 75.238281 168.496093 168.050781 168.496093s168.050781-75.4375 168.050781-168.496093zm0 0" fill="#89C8FD" />
            <path d="m218.050781 126.058594c-8.625 0-17.101562.652344-25.375 1.910156 80.769531 12.265625 142.671875 82.175781 142.671875 166.585938 0 84.410156-61.902344 154.320312-142.671875 166.585937 8.273438 1.257813 16.75 1.910156 25.375 1.910156 92.8125 0 168.050781-75.4375 168.050781-168.496093 0-93.058594-75.238281-168.496094-168.050781-168.496094zm0 0" fill="#60B6FF" />
            <g fill="#BAEb6C">
                <path d="m196.207031 191.542969c-25.886719 16.222656-45.304687 42.175781-30.742187 71.378906 14.5625 29.199219 46.921875 17.84375 76.855468 13.789063 29.933594-4.054688 7.28125 88.414062 25.078126 111.125 17.796874 22.710937 67.953124-22.710938 63.101562-51.914063-7.1875-43.234375-9.707031-58.402344 48.539062-89.222656l.15625-.058594c-19.632812-66.480469-79.0625-115.808594-150.621093-120.242187l-.007813.253906s-6.472656 48.667968-32.359375 64.890625zm0 0" />
                <path d="m323.339844 163.238281c.011718.007813.023437.019531.035156.027344-.011719-.007813-.023438-.015625-.035156-.027344zm0 0" />
                <path d="m343.890625 182.894531c0 .003907.003906.007813.007813.007813-.003907-.003906-.003907-.003906-.007813-.007813zm0 0" />
                <path d="m106.410156 370.800781c-7.773437-11.855469 13.753906-39.742187 18.605469-60.832031 4.855469-21.089844-24.269531-19.46875-33.167969-26.769531-8.898437-7.300781-21.035156-38.933594-29.933594-46.234375l-1.421874-1.121094c-6.78125 18.28125-10.492188 38.0625-10.492188 58.710938 0 55.695312 26.953125 105.070312 68.492188 135.746093l.050781-3.53125s6.472656-27.578125-12.132813-55.96875zm0 0" />
                <path d="m330.5 335.921875c-.035156-.210937-.070312-.417969-.105469-.628906-3.214843 12.988281-7.933593 25.382812-13.960937 36.984375 9.558594-11.28125 15.945312-25.035156 14.066406-36.355469zm0 0" />
            </g>
            <path d="m228.574219 126.398438-.007813.253906s-.539062 4.0625-1.929687 10.222656c58.929687 22.304688 102.074219 76.859375 108.011719 142.203125 7.425781-10.15625 21.007812-20.007813 44.390624-32.378906l.15625-.058594c-19.632812-66.480469-79.0625-115.808594-150.621093-120.242187zm0 0" fill="#96D629" />
            <path d="m217.824219 512c-11.902344 0-23.890625-.976562-35.871094-2.957031-57.269531-9.457031-107.429687-40.648438-141.238281-87.828125-33.808594-47.179688-47.21875-104.703125-37.757813-161.96875.683594-4.148438 4.613281-6.953125 8.75-6.269532 4.148438.683594 6.957031 4.601563 6.269531 8.75-8.792968 53.253907 3.675782 106.746094 35.117188 150.621094s78.082031 72.878906 131.34375 81.675782c109.949219 18.164062 214.152344-56.511719 232.3125-166.445313 8.792969-53.253906-3.675781-106.746094-35.117188-150.621094-31.4375-43.875-78.082031-72.878906-131.339843-81.675781-4.148438-.683594-6.957031-4.601562-6.273438-8.75.6875-4.148438 4.613281-6.953125 8.75-6.269531 57.273438 9.457031 107.433594 40.648437 141.238281 87.828125 33.808594 47.179687 47.21875 104.703125 37.761719 161.96875s-40.652343 107.421875-87.835937 141.226562c-37.316406 26.734375-81.097656 40.714844-126.109375 40.714844zm0 0" fill="#09A66D" />
            <path d="m171.605469 183.886719c-81.265625-13.789063-131.679688-116.109375-131.679688-116.109375s81.347657-79.964844 162.613281-66.179688c81.265626 13.785156 131.679688 116.105469 131.679688 116.105469s-81.347656 79.96875-162.613281 66.183594zm0 0" fill="#49CB5C" />
            <path d="m202.539062 1.597656c-15.519531-2.632812-31.035156-1.839844-45.992187 1.15625 77.871094 17.484375 125.90625 114.949219 125.90625 114.949219s-53.25 52.339844-116.625 65.027344c1.910156.429687 3.832031.824219 5.777344 1.15625 81.265625 13.785156 162.613281-66.183594 162.613281-66.183594s-50.414062-102.320313-131.679688-116.105469zm0 0" fill="#07B128" />
            <path d="m329.625 109.207031-79.472656-13.40625-74.234375-95.585937c-5.921875.347656-11.789063 1.105468-17.578125 2.191406l69.625 89.648438-59.925782-10.109376-51.132812-66.15625c-4.796875 2.164063-9.460938 4.460938-13.957031 6.84375l42.957031 55.578126-99.089844-16.71875c-4.449218 3.886718-6.890625 6.285156-6.890625 6.285156s1.632813 3.308594 4.765625 8.796875l98.550782 16.625-54.65625 42.261719c3.519531 3.625 7.210937 7.207031 11.070312 10.683593l64.015625-49.496093 63.441406 10.703124-86.28125 66.753907c5.355469 2.5 10.890625 4.664062 16.597657 6.40625l90.113281-69.710938 79.523437 13.414063c4.609375-4.019532 7.152344-6.511719 7.152344-6.511719s-1.574219-3.1875-4.59375-8.496094zm0 0" fill="#09A66D" />
            <path d="m389.492188 84.566406c21.800781-51.585937 97.335937-68.953125 97.335937-68.953125s40.191406 66.269531 18.390625 117.855469c-21.796875 51.585938-97.335938 68.953125-97.335938 68.953125s-40.191406-66.269531-18.390624-117.855469zm0 0" fill="#49CB5C" />
            <path d="m486.828125 15.613281s-17.175781 3.953125-37.511719 13.679688c10.433594 22.101562 26.972656 66.957031 11.242188 104.175781-11.402344 26.984375-37.511719 44.605469-59.820313 55.273438 4.027344 8.53125 7.148438 13.679687 7.148438 13.679687s75.535156-17.367187 97.335937-68.953125c21.796875-51.585938-18.394531-117.855469-18.394531-117.855469zm0 0" fill="#07B128" />
            <path d="m511.394531 90.921875-58.085937 24.367187 38.136718-91.175781c-2.730468-5.390625-4.617187-8.5-4.617187-8.5s-3.59375.828125-9.441406 2.65625l-39.414063 94.226563-24.441406-60.136719c-4.140625 3.652344-8.046875 7.621094-11.589844 11.921875l27.695313 68.144531-26.015625 62.195313c2.539062 4.957031 4.261718 7.800781 4.261718 7.800781s3.90625-.898437 10.1875-2.890625l26.863282-64.21875 66.4375-27.867188c.441406-5.519531.417968-11.054687.023437-16.523437zm0 0" fill="#09A66D" />
        </svg>
    )
}

const IconNaturePreservation = () => {
    return (
        <svg viewBox="0 0 60 60">
            <path d="m59 56h-58a1 1 0 0 1 0-2h58a1 1 0 0 1 0 2z" fill="#AA6A2B" />
            <path d="m59 60h-58a1 1 0 0 1 0-2h58a1 1 0 0 1 0 2z" fill="#AA6A2B" />
            <path d="m8 47h4a0 0 0 0 1 0 0v7a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1-1v-7a0 0 0 0 1 0 0z" fill="#8E5928" />
            <path d="m49 47h2a1 1 0 0 1 1 1v7a0 0 0 0 1 0 0h-4a0 0 0 0 1 0 0v-7a1 1 0 0 1 1-1z" fill="#8E5928" transform="matrix(-1 0 0 -1 99.996 102)" />
            <path d="m44.728 42-4.728 6h18.348a.71.71 0 0 0 .51-1.14l-3.59-4.86-5.268-1z" fill="#7DBD29" />
            <path d="m46.194 37-3.094 4.188a.5.5 0 0 0 .361.812h13.074a.5.5 0 0 0 .361-.812l-3.096-4.188-3.8-1z" fill="#90CF4A" />
            <path d="m49.555 30.244-4.325 5.527a.753.753 0 0 0 .476 1.229h8.65a.753.753 0 0 0 .476-1.229l-4.325-5.527a.587.587 0 0 0 -.952 0z" fill="#7DBD29" />
            <path d="m15.272 42 4.728 6h-18.348a.71.71 0 0 1 -.51-1.14l3.59-4.86 5.268-1z" fill="#7DBD29" />
            <path d="m13.806 37 3.094 4.188a.5.5 0 0 1 -.361.812h-13.074a.5.5 0 0 1 -.361-.812l3.096-4.188 3.8-1z" fill="#90CF4A" />
            <path d="m10.445 30.244 4.325 5.527a.753.753 0 0 1 -.476 1.229h-8.65a.753.753 0 0 1 -.476-1.229l4.325-5.527a.587.587 0 0 1 .952 0z" fill="#7DBD29" />
            <path d="m51.99 15.15c-1.28-8.84-10.63-14.15-21.99-14.15h-.01a.01.01 0 0 1 -.01.01v-.01c-11.35.01-20.69 5.32-21.97 14.15a1 1 0 0 0 1.43 1.07 8.893 8.893 0 0 1 3.96-1.01 9.269 9.269 0 0 1 4.98 1.62.981.981 0 0 0 1.11 0 9.237 9.237 0 0 1 4.98-1.62 9.23 9.23 0 0 1 4.97 1.62 1.007 1.007 0 0 0 1.12 0 9.23 9.23 0 0 1 4.97-1.62 9.237 9.237 0 0 1 4.98 1.62 1.055 1.055 0 0 0 .47.16.923.923 0 0 0 .64-.16 9.269 9.269 0 0 1 4.98-1.62 8.893 8.893 0 0 1 3.96 1.01 1 1 0 0 0 1.43-1.07z" fill="#EC3A48" />
            <path d="m30 17a1.023 1.023 0 0 1 -.56-.17 9.23 9.23 0 0 0 -4.97-1.62 9.237 9.237 0 0 0 -4.98 1.62 1 1 0 0 1 -.49.17s-1-10.99 10.98-15.99z" fill="#D92936" />
            <path d="m50.56 16.22a8.893 8.893 0 0 0 -3.96-1.01 9.269 9.269 0 0 0 -4.98 1.62.923.923 0 0 1 -.64.16c.03-.4.77-11.07-10.98-15.99 11.36 0 20.71 5.31 21.99 14.15a1 1 0 0 1 -1.43 1.07z" fill="#D92936" />
            <path d="m28 48h4a0 0 0 0 1 0 0v10a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1-1v-10a0 0 0 0 1 0 0z" fill="#8E5928" />
            <path d="m36.537 39 6.257 8.375a1.008 1.008 0 0 1 -.73 1.625h-24.128a1.008 1.008 0 0 1 -.73-1.625l6.257-8.375 6.537-1z" fill="#90CF4A" />
            <path d="m34.63 31 5.139 6.341a1.01 1.01 0 0 1 -.7 1.659h-18.141a1.01 1.01 0 0 1 -.7-1.659l5.142-6.341 4.63-1z" fill="#7DBD29" />
            <path d="m30.767 21.348 6.966 7.9a1.054 1.054 0 0 1 -.766 1.752h-13.934a1.054 1.054 0 0 1 -.766-1.756l6.966-7.9a1.019 1.019 0 0 1 1.534.004z" fill="#90CF4A" />
        </svg>
    )
}

const IconFirstAidBox = () => {
    return (
        <svg viewBox="0 0 128 128">
            <g>
                <g>
                    <g fill="#607D8B">
                        <path d="m36 25h-16c-.5522461 0-1 .4477539-1 1v4c0 .5522461.4477539 1 1 1h16c.5522461 0 1-.4477539 1-1v-4c0-.5522461-.4477539-1-1-1z" />
                        <path d="m108 25h-16c-.5522461 0-1 .4477539-1 1v4c0 .5522461.4477539 1 1 1h16c.5522461 0 1-.4477539 1-1v-4c0-.5522461-.4477539-1-1-1z" />
                        <path d="m76 9h-24c-4.9624023 0-9 4.0375977-9 9v12c0 .5522461.4477539 1 1 1h6c.5522461 0 1-.4477539 1-1v-12c0-.5327148.4672852-1 1-1h24c.5327148 0 1 .4672852 1 1v12c0 .5522461.4477539 1 1 1h6c.5522461 0 1-.4477539 1-1v-12c0-4.9624023-4.0375977-9-9-9z" />
                    </g>
                    <path d="m112 29h-96c-7.168457 0-13 5.831543-13 13v64c0 7.168457 5.831543 13 13 13h96c7.168457 0 13-5.831543 13-13v-64c0-7.168457-5.831543-13-13-13z" fill="#EF5350" />
                    <path d="m64 41c-18.1962891 0-33 14.8037109-33 33s14.8037109 33 33 33 33-14.8037109 33-33-14.8037109-33-33-33z" fill="#E0E0E0" />
                    <path d="m84 67h-13v-13c0-.5522461-.4477539-1-1-1h-12c-.5522461 0-1 .4477539-1 1v13h-13c-.5522461 0-1 .4477539-1 1v12c0 .5522461.4477539 1 1 1h13v13c0 .5522461.4477539 1 1 1h12c.5522461 0 1-.4477539 1-1v-13h13c.5522461 0 1-.4477539 1-1v-12c0-.5522461-.4477539-1-1-1z" fill="#EF5350" />
                    <g>
                        <path d="m8 57c-.5522461 0-1-.4477539-1-1v-14c0-.5522461.4477539-1 1-1s1 .4477539 1 1v14c0 .5522461-.4477539 1-1 1z" fill="#EF9A9A" />
                    </g>
                    <g>
                        <path d="m8 67c-.5522461 0-1-.4477539-1-1v-4c0-.5522461.4477539-1 1-1s1 .4477539 1 1v4c0 .5522461-.4477539 1-1 1z" fill="#EF9A9A" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export { IconSignBoard, IconDrivingHands, IconStackOfBooks, IconCarService, IconNaturePreservation, IconPlanetEarth, IconFirstAidBox };