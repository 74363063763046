import "./index.css";
import React from "react";

const Loading = () => {
    return (
        <div className="container-loading">
            <div className="spinner-border" role="status">
                <span className="d-none sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default Loading;