const errorCreateAcount = {
    type: null,
    error: null
};

const acountService = {
    async createAccount(data) {
        const apiURL = process.env.REACT_APP_API + '/signup';

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json' 
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            if (data.status === "success") {
                localStorage.setItem('userName', data.userName);
                localStorage.setItem('userEmail', data.userEmail);
                localStorage.setItem('token', data.token);

                return true;
            }
            if (data.status === "error") {                
                errorCreateAcount.type = data.type;
                errorCreateAcount.error = data.error;
                
                return false;
            }
        })
        .catch((error) => {
            return error;
        });
    },
    errorCreateAccount() {
        return errorCreateAcount;
    },
    async delete(data) {
        const apiURL = process.env.REACT_APP_API + '/delete';
        const token = localStorage.getItem('token') ?? null;

        let headers = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return await fetch(apiURL, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return error;
        });
    }
};

export default acountService;